import React, { Component, Fragment } from "react";
import { Modal } from "reactstrap";
import { RoundedButton } from "./";
import closeSRC from "../../Assets/Images/close.svg";
import LoginActions from "../../redux/LoginRedux";
import OrderActions from "../../redux/OrderRedux";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import fonts from "../../theme/fonts";

class PhoneModal extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      code: props.orderState.phoneNumber,
      buttonDisabled:
        props.orderState.phoneNumber === null
          ? true
          : props.orderState.phoneNumber.length > 7
          ? false
          : true,
    };
  }

  codeOnChange = (value, country, e, formattedValue) => {
    if (country.countryCode === "ar") {
      this.setState({
        code: value,
        error: Number(value.substring(2, 3)) === 9 ? true : false,
        buttonDisabled:
          Number(value.substring(2, 3)) === 9 || value.length < 8
            ? true
            : false,
      });
    } else {
      this.setState({
        code: value,
        error: false,
        buttonDisabled: value.length < 8 ? true : false,
      });
    }
  };

  onClose = () => {
    window.mixpanel.track("Click Close Insert Phone", { view: "phoneModal" });
    this.props.onClose();
  };

  onClick = async () => {
    if (!this.state.buttonDisabled) {
      window.mixpanel.track("Insert Phone", {
        view: "phoneModal",
        phone: this.state.code,
      });
      if (this.props.user) {
        this.props.setCurrentPhone(this.state.code);
      }
      this.props.setPhoneNumber(this.state.code);
      this.setState({ buttonDisabled: true });
      this.props.onCodeConfirm();
    }
  };

  render() {
    return (
      <Fragment>
        <Modal isOpen={this.props.state} centered>
          <div className="modalContainer">
            <div className="imgContent" onClick={this.onClose}>
              <img src={closeSRC} className="closeImg" alt="close" />
            </div>
            <div className="header">
              <span className="title">Ingresá tu celular</span>
              <span className="subtitle">
                {this.props.orderState.orderType === "OnPremise"
                  ? "Lo necesitamos para avisarte cuando tu pedido está listo"
                  : "Lo necesitamos para enviarte actualizaciones del estado de tu pedido"}
              </span>
            </div>
            <center>
              <div className="bloque">
                <PhoneInput
                  inputProps={{
                    autoFocus: true,
                  }}
                  countryCodeEditable={false}
                  country={"ar"}
                  value={this.state.code}
                  onChange={(value, country, e, formattedValue) =>
                    this.codeOnChange(value, country, e, formattedValue)
                  }
                />
              </div>
            </center>
            {this.state.error && (
              <div className="errorContainer">
                <span className="error">Ingresá tu número sin 9 adelante</span>
              </div>
            )}
          </div>
          <div className="bottomButtonContainer">
            <RoundedButton
              disabled={this.state.buttonDisabled}
              textColor="white"
              onClick={this.onClick}
            >
              CONTINUAR
            </RoundedButton>
          </div>
        </Modal>
        <style jsx="jsx">
          {`
          input {
            width: 100%;
            border: 0;
            border-bottom: 1px solid #dcdada;
            outline-color: transparent;
            font-size: 18px;
            line-height: 25px;
            font-weight: 600;
            font-family: ${fonts.main};
            font-style: normal;
          }
          ::placeholder {
            font-family: ${fonts.main};
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 25px;
            color: #B6B6B6;
          }
          .bloque{
            justify-content:center;
            font-family: 'Roboto',sans-serif;
            font-size: 15px;
            position: relative;
            width: 100%;
            display: table-cell;
          }
          
          .bottomButtonContainer {
            padding-left: 16px;
            padding-right: 16px;
            margin-bottom:16px;
          }
          .modalContainer {
            padding: 15px;
          }
          .header {
            margin-bottom: 25px;
            margin-top: 10px;
            text-align: center;
            display: flex;
            flex-direction: column;
          }
          .title {
            font-family: ${fonts.main};
            font-style: normal;
            font-weight: 600;
            font-size: 19px;
            line-height: 26px;
            text-align: center;
            letter-spacing: 0.144375px;
            color: #000000;
            margin-bottom 5px;
          }
          .subtitle {
            font-family: ${fonts.main};
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 23px;
            text-align: center;
            color: #4A4A4A;
            padding-left: 20px;
            padding-right: 20px;
          }
          .imgContent {
            height: 20px;
            display: flex;
            justify-content: flex-end;
            cursor:pointer;
          }
          .closeImg {
            padding-right: 0px;
          }
          .container{
            text-align: center;
          }
          .loadingImg{
            witdth: 80px;
            height: 80px;
          }
          .error {
            font-size: 14px;
            color: red;
          }
          .errorContainer {
            text-align: center;
            margin-top: 10px;
          }
           `}
        </style>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.session.user,
    orderState: state.order,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPhone: (phone) => dispatch(LoginActions.setCurrentPhone(phone)),
    setPhoneNumber: (phone) => dispatch(OrderActions.setPhoneNumber(phone)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneModal);
