// import config from '../static/config/config'
const Payment = (api) => {

    const getAccountMpPaymentPreference = (accountId,domain) => api.get('/locales/cuenta/' + accountId+'/mppaymentpreference?smartCheckout=true&returnDomain='+domain);
    const getPaymentMethods = (clientId) => api.get('/locales/localPaymenth/?local=' + clientId);
    const sendPayment = (orderId, paymentId) => api.post(`/locales/order/${orderId}/payment`,{id:paymentId})
    return {
        getAccountMpPaymentPreference,
        getPaymentMethods,
        sendPayment
    }
};
export default Payment;
