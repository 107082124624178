const Nps = (api) => {

  const createNps = (ticketId,data) => api.post('locales/me/ticket/'+ ticketId+'/nps',data);
  const createBarReview = (ticketId,data) => api.post('locales/me/ticket/'+ ticketId+'/barReview',data);
  const getNpsSurvey = (ticketId) => api.get('locales/me/npsSurvey', {ticket:ticketId});


  return {
    createNps,
    createBarReview,
    getNpsSurvey
  }
};
export default Nps;
