import React, { Component, Fragment, Suspense } from "react";
import { Provider } from "react-redux";
import createStore from "../../redux";
import { FudexIcon, GoBackIcon, UserIcon } from "./components";
import { PersistGate } from "redux-persist/integration/react";
import { FdxText } from "../Common";
import NavigationBar from "../NavigationBar";
import ProductNavigationBar from "../ProductNavigationBar";
import Loading from "../Common/Loading";
import WebFontloader from "webfontloader";
import { Helmet } from "react-helmet";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ErrorBoundary from "../Errors/ErrorBoundary";
import {
  Menu,
  ProductDetail,
  OrderDetail,
  Tickets,
  UserData,
  Login,
  SignUp,
  SignUpSelector,
  TicketDetail,
  TicketRetired,
  Nps,
  Delivery,
  Onpremise,
  Dashboard,
  PaymentType,
  TransactionSuccess,
  OrderProcess,
  PartialRetire,
  Conditions,
} from "../../containers";
import GoogleAnalytics from "../Common/GoogleAnalytics";
const { store, persistor } = createStore();

class App extends Component {
  constructor(props) {
    super();

    this.state = {
      showModal: false,
    };
  }

  componentDidMount = () => {
    WebFontloader.load({
      google: {
        families: [
          "Sriracha",
          "Montserrat",
          "Open Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap",
          "Montserrat:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap"
        ],
      },
      timeout: 5000 // Set the timeout to two seconds
    });
  };

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // rol(error, info);
  }
  render() {
    return (
      <Suspense fallback={<Loading />}>
        <Helmet>
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css"
          />
        </Helmet>
        <ErrorBoundary>
          <PersistGate loading={<Loading />} persistor={persistor}>
            <Router>
              <Fragment>
                <Provider store={store}>
                  <Switch>
                    <Redirect exact from="/local/:code/menu" to="/:code" />
                    <Route
                      exact
                      path="/koi/Delivery"
                      render={() =>
                        (window.location = "https://koi.fudex.menu/delivery")
                      }
                    />
                    <Route
                      exact
                      path="/itamae/Delivery"
                      render={() =>
                        (window.location = "https://itamae.fudex.menu/delivery")
                      }
                    />
                    <Route
                      exact
                      path="/lolamora/Delivery"
                      render={() =>
                        (window.location =
                          "https://lolamora.fudex.menu/delivery")
                      }
                    />
                    <Route
                      path="/dashboard"
                      exact
                      render={(props) => (
                        <Onpremise
                          {...props}
                          navBar={
                            <NavigationBar
                              leftElement={<GoBackIcon visibility="hidden" />}
                              middleElement={<FudexIcon />}
                              rightElement={<UserIcon />}
                            />
                          }
                        />
                      )}
                    />
                    <Route
                        path="/"
                        exact
                        render={(props) => (
                            <Dashboard
                                {...props}
                            />
                        )}
                    />
                    <Route
                      path="/userdata"
                      exact
                      render={(props) => (
                        <UserData
                          {...props}
                          navBar={
                            <NavigationBar
                              leftElement={
                                <GoBackIcon
                                  onClick={() => props.history.goBack()}
                                />
                              }
                              middleElement={
                                <FdxText fontSize="18px" color="white">
                                  Tus datos
                                </FdxText>
                              }
                              rightElement={<UserIcon visibility="hidden" />}
                            />
                          }
                        />
                      )}
                    />
                    <Route
                      path="/tickets"
                      exact
                      render={(props) => (
                        <Tickets
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path="/delivery"
                      exact
                      render={(props) => (
                        <Delivery
                          {...props}
                          navBar={
                            <NavigationBar
                              leftElement={<GoBackIcon visibility="hidden" />}
                              middleElement={<FudexIcon />}
                              rightElement={<UserIcon />}
                            />
                          }
                        />
                      )}
                    />
                    <Route
                      path="/conditions"
                      exact
                      render={(props) => (
                        <Conditions
                          {...props}
                          navBar={
                            <NavigationBar
                              leftElement={
                                <GoBackIcon
                                  onClick={() => props.history.goBack()}
                                />
                              }
                              middleElement={
                                <FdxText fontSize="18px" color="white">
                                  Términos y condiciones
                                </FdxText>
                              }
                              rightElement={<UserIcon visibility="hidden" />}
                            />
                          }
                        />
                      )}
                    />
                    <Route
                      path="/login"
                      exact
                      render={(props) => (
                        <Login
                          {...props}
                          navBar={
                            <NavigationBar
                              leftElement={
                                <GoBackIcon
                                  onClick={() => {
                                      if (localStorage.getItem('currentBar'))
                                          props.history.push('/' + localStorage.getItem('currentBar'));

                                      else
                                          props.history.goBack();
                                  }}
                                />
                              }
                              middleElement={
                                <FdxText fontSize="18px" color="white">
                                  Ingresá ahora
                                </FdxText>
                              }
                              rightElement={<UserIcon visibility="hidden" />}
                            />
                          }
                        />
                      )}
                    />
                    <Route
                      path="/signup"
                      exact
                      render={(props) => (
                        <SignUp
                          {...props}
                          navBar={
                            <NavigationBar
                              leftElement={
                                <GoBackIcon
                                  onClick={() => {
                                      if (localStorage.getItem('currentBar'))
                                          props.history.push('/' + localStorage.getItem('currentBar'));

                                      else
                                          props.history.goBack();
                                  }}
                                />
                              }
                              middleElement={
                                <FdxText fontSize="18px" color="white">
                                  Registrate
                                </FdxText>
                              }
                              rightElement={<UserIcon visibility="hidden" />}
                            />
                          }
                        />
                      )}
                    />
                    <Route
                      path="/signupselector"
                      exact
                      render={(props) => (
                        <SignUpSelector
                          {...props}
                          navBar={
                            <NavigationBar
                              leftElement={
                                <GoBackIcon
                                  onClick={() => props.history.goBack()}
                                />
                              }
                              middleElement={
                                <FdxText fontSize="18px" color="white">
                                  Registrate
                                </FdxText>
                              }
                              rightElement={<UserIcon visibility="hidden" />}
                            />
                          }
                        />
                      )}
                    />
                    <Route
                      path="/tickets/ticket/:id"
                      exact
                      render={(props) => (
                        <TicketDetail
                          {...props}
                          navBar={
                            <NavigationBar
                              leftElement={
                                <GoBackIcon
                                  onClick={() => {
                                      if (props.location.goBackTicket)
                                          props.history.goBack()

                                      else
                                        props.history.push("/tickets")
                                  }}
                                />
                              }
                              middleElement={<FudexIcon />}
                              rightElement={<UserIcon visibility="hidden" />}
                            />
                          }
                        />
                      )}
                    />
                    <Route
                      path="/tickets/ticket/:id/retired"
                      exact
                      render={(props) => (
                        <TicketRetired {...props} navBar={<NavigationBar />} />
                      )}
                    />
                    <Route
                      path="/tickets/ticket/:id/nps"
                      exact
                      render={(props) => (
                        <Nps {...props} navBar={<NavigationBar />} />
                      )}
                    />
                    <Route
                      path="/tickets/ticket/:id/partialretire"
                      exact
                      render={(props) => (
                        <PartialRetire
                          {...props}
                          navBar={
                            <NavigationBar
                              leftElement={
                                <GoBackIcon
                                  onClick={() => props.history.goBack()}
                                />
                              }
                              middleElement={<FudexIcon />}
                              rightElement={<UserIcon visibility="hidden" />}
                            />
                          }
                        />
                      )}
                    />
                    <Route
                      path="/:orderType/:code/order"
                      exact
                      render={(props) => <OrderDetail {...props} />}
                    />
                    <Route
                      path="/:code/order"
                      exact
                      render={(props) => <OrderDetail {...props} />}
                    />
                    <Route
                      path="/:code/product/:productId"
                      exact
                      render={(props) => (
                        <ProductDetail
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path="/:orderType/:code/product/:productId"
                      exact
                      render={(props) => (
                        <ProductDetail
                          {...props}
                        />
                      )}
                    />

                    <Route path="/:code/success" exact
                           render={(props) => <TransactionSuccess {...props} />}
                    />
                    <Route
                      path="*/:code/orderprocess"
                      exact
                      render={(props) => <OrderProcess {...props} />}
                    />
                    <Route
                      path="*/:code/paymentchoice"
                      exact
                      render={(props) => <PaymentType {...props} />}
                    />
                    <Route
                      path="/:orderType/:code"
                      exact
                      render={(props) => <Menu {...props} />}
                    />
                    <Route
                      path="/:code"
                      exact
                      render={(props) => <Menu {...props} />}
                    />
                  </Switch>
                  <GoogleAnalytics />
                </Provider>
              </Fragment>
            </Router>
          </PersistGate>
        </ErrorBoundary>
        <style jsx global>{`
          body {
            background-color: #f1f2f5 !important;
          }
        `}</style>
      </Suspense>
    );
  }
}

export default App;
