import React, { Fragment } from "react";
import { Modal } from "reactstrap";
import { FdxText, LinkButton } from "./";
import colors from "../../theme/colors";

const AlertModal = ({ title, message, onClose, state }) => (
  <Fragment>
    <Modal isOpen={state} centered={true}>
      <div className="modalContainer">
        <div>
          <FdxText color="black" fontSize="18px" textAlign="center">
            {title}
          </FdxText>
        </div>
        <div>
          <FdxText color="black" fontSize="14px" textAlign="center">
            {message}
          </FdxText>
        </div>
        <div className="bottomDiv">
          <LinkButton underline="none" onClick={onClose} color={colors.main}>
            OK
          </LinkButton>
        </div>
      </div>
    </Modal>
    <style jsx="jsx">
      {`
        .modalContainer {
          padding: 20px;
          display: flex;
          flex-direction: column;
        }
        .bottomDiv {
          align-self: flex-end;
        }
      `}
    </style>
  </Fragment>
);

export default AlertModal;
