import { call, put } from "redux-saga/effects";
import LoginActions from "../redux/LoginRedux";
import { select } from "redux-saga/effects";

export function* login(api, { username, password, manager }) {
  const response = yield call(api.login.login, username, password, manager);
  if (response.ok) {
    /*--------------HotJar--------------*/
    if (window.config.hotJarEnabled) {
      window.hj("identify", response.data.id.toString(), {
        email: response.data.email,
      });
    }
    /*--------------Mixpanel--------------*/
    window.mixpanel.track("Mail Login OK", { view: "loginSagas" });
    window.mixpanel.identify(response.data.id.toString());
    window.mixpanel.people.set({
      $email: response.data.email,
      $last_login: new Date().toString(),
      first_name: response.data.first_name,
      last_name: response.data.last_name,
      fdx_id: response.data.id.toString(),
    });
    /*--------------Mixpanel--------------*/
    yield call(
      api.setHeader,
      "Authorization",
      "Token " + response.headers["auth-token"]
    );
    yield put(
      LoginActions.loginSuccess(response.data, response.headers["auth-token"])
    );
  } else {
    /*--------------Mixpanel--------------*/
    window.mixpanel.track("Mail Login Fail", {
      view: "loginSagas",
      error: response,
    });
    /*--------------Mixpanel--------------*/
    yield put(LoginActions.loginFailure(response));
  }
}

export function* logout(api) {
  yield call(api.login.logout);
  /*--------------Mixpanel--------------*/
  window.mixpanel.track("Log Out OK", { view: "loginSagas" });
  window.mixpanel.reset();
  /*--------------Mixpanel--------------*/
  yield call(api.setHeader, "Authorization", "");
}

export function* signUp(api, { first_name, last_name, email, password, manager }) {
  console.log(manager)
  const response = yield call(
    api.login.signUp,
    first_name,
    last_name,
    email,
    password,
    manager
  );
  if (response.ok) {
    /*--------------HotJar--------------*/
    if (window.config.hotJarEnabled) {
      window.hj("identify", response.data.id.toString(), {
        email: response.data.email,
      });
    }
    /*--------------Mixpanel--------------*/
    window.mixpanel.track("Mail SignUp OK", { view: "loginSagas" });
    window.mixpanel.identify(response.data.id.toString());
    window.mixpanel.alias(response.data.id.toString());
    window.mixpanel.people.set({
      $email: response.data.email,
      $last_login: new Date().toString(),
      first_name: response.data.first_name,
      last_name: response.data.last_name,
      fdx_id: response.data.id.toString(),
    });
    /*--------------Mixpanel--------------*/
    yield call(
      api.setHeader,
      "Authorization",
      "Token " + response.headers["auth-token"]
    );
    yield put(
      LoginActions.signUpSuccess(response.data, response.headers["auth-token"])
    );
  } else {
    /*--------------Mixpanel--------------*/
    window.mixpanel.track("Mail SignUp Fail", {
      view: "loginSagas",
      error: response,
    });
    /*--------------Mixpanel--------------*/
    yield put(LoginActions.signUpFailure(response));
  }
}

export function* persistSessionData(api, data) {
  const { user } = data;
  let address = yield select((state) => {
    return state.session.address;
  });

  if (address) {
    if (!address.id) {
      const response = yield call(api.login.createAddress, address);
      if (response.ok) {
        yield put(LoginActions.setCurrentAddress(response.data));
      }
    } else {
      //yield put(LoginActions.persistSessionDataSuccess());
    }
  } else {
    if (user.addresses && user.addresses.length > 0) {
      yield put(
        LoginActions.setCurrentAddress(
          user.addresses[user.addresses.length - 1]
        )
      );
    } else {
      //yield put(LoginActions.persistSessionDataSuccess());
    }
  }
  yield put(LoginActions.persistSessionDataSuccess());
}

export function* facebookLogin(api, { accessToken }) {
  const response = yield call(api.login.facebookLogin, accessToken);
  if (response.ok) {
    /*--------------HotJar--------------*/
    if (window.config.hotJarEnabled) {
      window.hj("identify", response.data.id.toString(), {
        email: response.data.email,
      });
    }
    /*--------------Mixpanel--------------*/
    window.mixpanel.track("Facebook LoginOK", { view: "loginSagas" });
    if (response.status == 201) {
      window.mixpanel.alias(response.data.id.toString());
    } else {
      window.mixpanel.identify(response.data.id.toString());
    }
    window.mixpanel.people.set({
      $email: response.data.email,
      $last_login: new Date().toString(),
      first_name: response.data.first_name,
      last_name: response.data.last_name,
      fdx_id: response.data.id.toString(),
    });
    /*--------------Mixpanel--------------*/
    yield call(
      api.setHeader,
      "Authorization",
      "Token " + response.headers["auth-token"]
    );
    yield put(
      LoginActions.facebookLoginSuccess(
        response.data,
        response.headers["auth-token"]
      )
    );
  } else {
    /*--------------Mixpanel--------------*/
    window.mixpanel.track("Facebook LoginFail", {
      view: "loginSagas",
      error: response,
    });
    /*--------------Mixpanel--------------*/
    yield put(LoginActions.facebookLoginFailure("loginFBFailure"));
  }
}

export function* googleLogin(api, { id, accessToken, email, name, lastName }) {
  const response = yield call(api.login.googleLogin, id, accessToken, email, name, lastName);
  if (response.ok) {
    /*--------------HotJar--------------*/
    if (window.config.hotJarEnabled) {
      window.hj("identify", response.data.id.toString(), {
        email: response.data.email,
      });
    }
    /*--------------Mixpanel--------------*/
    window.mixpanel.track("Facebook LoginOK", { view: "loginSagas" });
    if (response.status == 201) {
      window.mixpanel.alias(response.data.id.toString());
    } else {
      window.mixpanel.identify(response.data.id.toString());
    }
    window.mixpanel.people.set({
      $email: response.data.email,
      $last_login: new Date().toString(),
      first_name: response.data.first_name,
      last_name: response.data.last_name,
      fdx_id: response.data.id.toString(),
    });
    /*--------------Mixpanel--------------*/
    yield call(
      api.setHeader,
      "Authorization",
      "Token " + response.headers["auth-token"]
    );
    yield put(
      LoginActions.facebookLoginSuccess(
        response.data,
        response.headers["auth-token"]
      )
    );
  } else {
    /*--------------Mixpanel--------------*/
    window.mixpanel.track("Facebook LoginFail", {
      view: "loginSagas",
      error: response,
    });
    /*--------------Mixpanel--------------*/
    yield put(LoginActions.facebookLoginFailure("loginFBFailure"));
  }
}

export function* loginGuest(api) {
  const response = yield call(api.login.loginGuest);
  if (response.ok) {
    /*--------------HotJar--------------*/
    if (window.config.hotJarEnabled) {
      window.hj("identify", response.data.id.toString(), {
        email: response.data.email,
      });
    }
    /*--------------Mixpanel--------------*/
    window.mixpanel.track("Guest Login OK", { view: "loginSagas" });
    window.mixpanel.identify(response.data.id.toString());
    window.mixpanel.people.set({
      $email: response.data.email,
      $last_login: new Date().toString(),
      first_name: response.data.first_name,
      last_name: response.data.last_name,
      fdx_id: response.data.id.toString(),
    });
    /*--------------Mixpanel--------------*/
    yield call(
      api.setHeader,
      "Authorization",
      "Token " + response.headers["auth-token"]
    );
    yield put(
      LoginActions.loginSuccess(response.data, response.headers["auth-token"])
    );
  } else {
    /*--------------Mixpanel--------------*/
    window.mixpanel.track("Guest Login Fail", {
      view: "loginSagas",
      error: response,
    });
    /*--------------Mixpanel--------------*/
    yield put(LoginActions.loginFailure(response));
  }
}
