import apisauce from 'apisauce'
import Menu from './Menu';
import Order from './Order';
import Payment from './Payment';
import Tickets from './Tickets';
import Locales from './Locales';
import Login from './Login';
import Nps from './Nps';

// import config from '../public/config/config'

const api = apisauce.create({
    baseURL: window.config.apiBase,
    timeout: 30000,
    headers: {
        'Cache-Control': 'no-cache',
        'WebApp-Version': '2.9.0',
    }

})

const getHeaders = () =>{
    return api.headers
}

export default {
    menu: Menu(api),
    order: Order(api),
    payment: Payment(api),
    tickets: Tickets(api),
    locales: Locales(api),
    login: Login(api),
    nps: Nps(api),
    setHeader: api.setHeader,
    setHeaders: api.setHeaders,
    getHeaders
};
