import React, {Component, Fragment} from 'react';
import FdxText from './FdxText';
import angleRightSRC from '../../Assets/Images/font-awesome_4-7-0_angle-right_16_0_000000_none.png';
import checkSRC from '../../Assets/Images/ic_check_black_24px.png';
import iconAdvSRC from '../../Assets/Images/Icon.png';

export default class EnterDetailButton extends Component {

  getText = () => {
    return this.props.text || this.props.children || '';
  }

  getSubText = () => {
    return this.props.subtext || '';
  }

  render = () => {
    const {backgroundColor,selected, onClick, id, name } = this.props;
    return (<Fragment>
      <button id={id} name={name}className="button" onClick={onClick}>
        <div className="leftCell">
          <div>
            <FdxText fontSize="12px" lineHeight="16px">{this.getText()}</FdxText>
          </div>
          <div>
            <FdxText lineHeight="25px" color={selected? '#000000' : null} fontSize="18px" marginTop="1px" marginBottom="1px">{this.getSubText()}</FdxText>
          </div>
        </div>
        <div className="rightCell">
          { !backgroundColor? null :
          <img className="leftImg" alt="iconAd" src={iconAdvSRC}/> }
          <img className="buttonCaret" alt="angleRight" src={selected? checkSRC: angleRightSRC }/>
        </div>
      </button>
      <style jsx="jsx">
        {
          ` .button {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;;
            background-color:${backgroundColor?backgroundColor:'#FFFFFF'};
            border-radius: 5px;
            box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.5);
            width: 100%;
            outline-color: transparent;
            border: 0px;

          }
          .leftImg {
            margin-right: 6px;
          }
          .leftCell {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          }
          .rightCell {
            display: flex;
            align-items: center;
          }
           `
        }</style>
    </Fragment>)
  }
}
