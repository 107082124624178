import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  localesRequest: ['latitude','longitude'],
  localesSuccess: ['response'],
  localesFailure: ['error'],
  setGeoLocation: ['geolocation'],
  setLocations: ['locations'],
  setFrecuentLocations: ['frecuentLocations'],
  setNearLocations: ['nearLocations'],
  setDeliveryLocations: ['deliveryLocations']
});

export const LocalesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  locales: [],
  error: null,
  fetching: false,
  geolocation: false
});

/* ------------- Reducers ------------- */

export const request = (state: Object) => {
  return {...state, fetching: true }
}

export const success = (state: Object, action: Object) => {
  const { response } = action;
  return { ...state, fetching: false, error: null, locales:response.results };
}

export const failure = (state: Object, { error }: Object) => {
  return { ...state, fetching: false, error }
}

export const setGeoLocation = (state, action) => {
  const { geolocation} = action;
    return {...state, geolocation:geolocation};
}

export const setLocations = (state, action) => {
  const { locations} = action;
    return {...state, locations:locations};
}

export const setFrecuentLocations = (state, action) => {
  const { frecuentLocations} = action
    return {...state, frecuentLocations};
}

export const setNearLocations = (state, action) => {
  const { nearLocations} = action
    return {...state, nearLocations};
}

export const setDeliveryLocations = (state, action) => {
  const { deliveryLocations} = action
    return {...state, deliveryLocations};
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOCALES_REQUEST]: request,
  [Types.LOCALES_SUCCESS]: success,
  [Types.LOCALES_FAILURE]: failure,
  [Types.SET_GEO_LOCATION]: setGeoLocation,
  [Types.SET_LOCATIONS]: setLocations,
  [Types.SET_FRECUENT_LOCATIONS]: setFrecuentLocations,
  [Types.SET_NEAR_LOCATIONS]: setNearLocations,
  [Types.SET_DELIVERY_LOCATIONS]: setDeliveryLocations,
})

/* ------------- Selectors ------------- */

export const getCurrentLocal = (state: Object, code) =>  {
  if (!code) return;
  if(!state.locales.locales) {return};
  return state.locales.locales.filter(local => local.code === code)[0];
}
