import React, { Fragment } from 'react';
import spinnerSRC from "../../Assets/Images/spinner.gif";

const Networks = (props) => {
    return <Fragment>
        <div className="container">
            <img className="loadingImg"  src={spinnerSRC} alt="instragram" />
        </div>
        <style jsx>{`
	.container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height:100%;
        width:100%;
      }

    .loadingImg{
        left: 50%;
        position: 'fixed'; 
        width: 200px;
        height: 200px;
        margin-top: 200px
    }
	`}</style>
    </Fragment>
}

export default Networks;

