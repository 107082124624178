import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  ticketsRequest: ["grabType"],
  ticketsSuccess: ["response"],
  ticketsFailure: ["error"],

  retireTicketRequest: ["ticketId", "code"], // code = código qr
  retireTicketSuccess: ["response"], // Ticket modificado
  retireTicketFailure: ["error"],

  resetTickets: [],
});

export const TicketsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  tickets: [],
  error: null,
  fetching: false,
});

/* ------------- Reducers ------------- */

export const ticketsRequest = (state: Object, action: Object) => {
  return { ...state, fetching: true, error: null };
};

export const ticketsSuccess = (state: Object, action: Object) => {
  const { response } = action;
  return { ...state, fetching: false, error: null, tickets: response.results };
};

export const ticketsFailure = (state: Object, { error }: Object) => {
  return { ...state, fetching: false, error };
};

export const retireTicketRequest = (state: Object, action: Object) => {
  return { ...state, fetching: true, error: null };
};

export const retireTicketSuccess = (state: Object, action: Object) => {
  const { response } = action;
  let ticketId = response.id;
  if (ticketId) {
    let ticketList = state.tickets.map((ticket) => {
      return ticket.id === ticketId ? response : ticket;
    });
    return { ...state, fetching: false, tickets: ticketList, error: null };
  }
  return {
    ...state,
    fetching: false,
    error: "Error actualizando estado de ticket",
  };
};
export const retireTicketFailure = (state: Object, { error }: Object) => {
  return { ...state, fetching: false, error };
};

export const resetTickets = (state: Object) => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.TICKETS_REQUEST]: ticketsRequest,
  [Types.TICKETS_SUCCESS]: ticketsSuccess,
  [Types.TICKETS_FAILURE]: ticketsFailure,

  [Types.RETIRE_TICKET_REQUEST]: retireTicketRequest,
  [Types.RETIRE_TICKET_SUCCESS]: retireTicketSuccess,
  [Types.RETIRE_TICKET_FAILURE]: retireTicketFailure,

  [Types.RESET_TICKETS]: resetTickets,
});

/* ------------- Selectors ------------- */

export const filterTicketByState = (tickets: Object, status: String) => {
  let empty = [];
  if (!tickets || !tickets.tickets || !status) {
    return empty;
  }
  if (status === "Retired") {
    return tickets.tickets.filter(
      (ticket) => ticket.status === status || ticket.status === "Canceled"
    );
  } else {
    return tickets.tickets.filter(
      (ticket) =>
        ticket.status === "Sent" ||
        ticket.status === "Pending" ||
        ticket.status === "Ready" ||
        ticket.status === "Preparing"
    );
  }
};

export const getTicketById = (tickets: Object, id: Number) => {
  if (!tickets || !id || isNaN(id)) {
    return null;
  }
  if (!tickets.tickets) return;
  return tickets.tickets.filter((ticket) => ticket.id === id)[0];
};
