// import config from '../static/config/config'
const Order = (api) => {

    const createOrder = (localId, order) => api.post(`locales/local/${localId}/order`, order);
    const saveAccountTableNumber = (accountId, payload) => api.put('/locales/cuenta/' + accountId + '/tableNumber', payload);


    return {
        createOrder,
        saveAccountTableNumber
    }
};
export default Order;
