import React from "react";
export const Menu = React.lazy(() =>
  import(/* webpackChunkName: "menu.container" */ "./Menu/Menu")
);
export const ProductDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "productDetail.container" */ "./ProductDetail/ProductDetail"
  )
);
export const Dashboard = React.lazy(() =>
  import(
    /* webpackChunkName: "productDetail.container" */ "./Dashboard/Dashboard"
  )
);
export const OrderDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "orderDetail.container" */ "./OrderDetail/OrderDetail"
  )
);
export const Tickets = React.lazy(() =>
  import(/* webpackChunkName: "tickets.container" */ "./Tickets/Tickets")
);
export const TicketDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "ticketDetail.container" */ "./TicketDetail/TicketDetail"
  )
);
export const UserData = React.lazy(() =>
  import(/* webpackChunkName: "UserData.container" */ "./User/UserData")
);
export const Login = React.lazy(() =>
  import(/* webpackChunkName: "login.container" */ "./Login/Login")
);
export const SignUp = React.lazy(() =>
  import(/* webpackChunkName: "signUp.container" */ "./SignUp/SignUp")
);
export const SignUpSelector = React.lazy(() =>
  import(
    /* webpackChunkName: "signUpSelector.container" */ "./SignUpSelector/SignUpSelector"
  )
);
export const TicketRetired = React.lazy(() =>
  import(
    /* webpackChunkName: "ticketRetired.container" */ "./TicketRetired/TicketRetired"
  )
);
export const Nps = React.lazy(() =>
  import(/* webpackChunkName: "nps.container" */ "./Nps/Nps")
);
export const Delivery = React.lazy(() =>
  import(/* webpackChunkName: "home.container" */ "./Delivery/Delivery")
);
export const Onpremise = React.lazy(() =>
  import(/* webpackChunkName: "onpremise.container" */ "./Onpremise/Onpremise")
);
export const PaymentType = React.lazy(() =>
  import(
    /* webpackChunkName: "paymentType.container" */ "./PaymentType/PaymentType"
  )
);
export const TransactionSuccess = React.lazy(() =>
  import(
    /* webpackChunkName: "transactionSuccess.container" */ "./TransactionSuccess/TransactionSuccess"
  )
);
export const OrderProcess = React.lazy(() =>
  import(
    /* webpackChunkName: "orderProcess.container" */ "./OrderProcess/OrderProcess"
  )
);
export const PartialRetire = React.lazy(() =>
  import(
    /* webpackChunkName: "partialRetire.container" */ "./PartialRetire/PartialRetire"
  )
);
export const Conditions = React.lazy(() =>
  import(
    /* webpackChunkName: "conditions.container" */ "./Conditions/Conditions"
  )
);
