import React, { Component, Fragment } from "react";
import { Modal } from "reactstrap";
import { RoundedButton, LinkButton } from "./";
import Api from "../../api";
import { withRouter } from "react-router-dom";
import fonts from "../../theme/fonts";

const GRAB_TYPE_KITCHEN = "Kitchen";

class CodeModal extends Component {
  constructor(props) {
    super();
    this.state = {
      code: "",
      buttonDisabled: true,
      error: null,
    };
  }

  codeOnChange = (event) => {
    this.setState({ code: event.target.value });
    if (event.target.value !== "") {
      this.setState({ buttonDisabled: false });
    } else {
      this.setState({ buttonDisabled: true });
    }
  };

  onClick = () => {
    if (!this.state.buttonDisabled) {
      this.retireTicket(this.state.code);
    }
  };

  componentDidMount = () => {
    this.inputCode.focus();
  };

  retireTicket = async (data) => {
    this.setState({ buttonDisabled: true });
    const payload = {
      items: this.props.producsToTake,
      code: data,
      type: this.props.producsToTake ? "Partial" : "Total",
    };

    const request = await Api.tickets.retireTicket(
      this.props.ticketId,
      payload
    );
    if (request.ok) {
      window.mixpanel.track("Manual Code Input OK", {
        view: "codeModal",
        TicketId: this.props.ticketId,
        qrDataRead: data,
      });
      this.props.history.push({
        pathname: `/tickets/ticket/${this.props.ticketId}/retired`,
        ticket: request.data,
      });
    } else {
      if (request.status === 400) {
        this.setState({ error: request.data.description });
        window.mixpanel.track("Manual Code Input Error", {
          view: "codeModal",
          TicketId: this.props.ticketId,
          Error: request.status,
          ErrorCode: request.data.code,
          ErrorDescription: request.data.description,
        });
      } else {
        this.setState({
          error: "Algo no salió como esperábamos. Intentá repetirlo más tarde.",
        });
        window.mixpanel.track("Manual Code Input Error", {
          view: "codeModal",
          TicketId: this.props.ticketId,
          Error: request.status,
        });
      }
    }
    this.setState({ buttonDisabled: false });
  };

  onClose = () => {
    this.setState({ error: null });
    this.props.onClose();
  };

  render() {
    return (
      <Fragment>
        <Modal isOpen={this.props.state}>
          <div className="modalContainer">
            <div className="header">
              <span className="title">
                Preguntale el código al{" "}
                {this.props.grab_type === GRAB_TYPE_KITCHEN
                  ? "cocinero"
                  : "bartender"}
              </span>
              <span className={`subtitle ${this.state.error ? "error" : ""}`}>
                {this.state.error
                  ? this.state.error
                  : "No lo ingreses antes de estar en la barra"}
              </span>
            </div>
            <div>
              <input
                placeholder="Ingresá el código"
                onChange={this.codeOnChange}
                ref={(c) => (this.inputCode = c)}
              />
            </div>
            <div className="bottomButtonContainer">
              <RoundedButton
                disabled={this.state.buttonDisabled}
                textColor="white"
                onClick={this.onClick}
              >
                ACEPTAR
              </RoundedButton>
            </div>
            <div className="link">
              <LinkButton onClick={this.onClose}>CANCELAR</LinkButton>
            </div>
          </div>
        </Modal>
        <style jsx="jsx">
          {`
          input {
            width: 100%;
            border: 0;
            border-bottom: 1px solid #dcdada;
            outline-color: transparent;
            font-size: 18px;
            line-height: 25px;
            font-weight: 600;
            font-family: ${fonts.main};
            font-style: normal;
          }
          ::placeholder {
            color: #C6CDD8;
            font-size: 14px;
            font-family: ${fonts.main};
            font-style: normal;
            lineHeight="14px"
          }
          .leftDiv {
            text-align: left;
          }
          .bottomButtonContainer {
            margin-top: 35px;
          }
          .modalContainer {
            padding: 15px;
          }
          .header {
            margin-bottom: 25px;
            text-align: center;
            display: flex;
            flex-direction: column;
          }
          .link {
            text-align: center;
          }
          .title {
            line-height: 33px;
            font-size: 24px;
            font-weight: 600;
            text-align: center;
            color: #000000;
          }
          .subtitle {
            line-height: 20px;
            font-size: 14px;
            margin-top: 10px;
            text-align: center;
            color: #4A4A4A;
          }
          .error {
            color: #C82027;
          }
           `}
        </style>
      </Fragment>
    );
  }
}

export default withRouter(CodeModal);
