import React,{Fragment} from "react";
import fudexLogoSRC from "../../../../Assets/Images/newFudexLogo.png";

const FudexIcon = () => {
  return (
    <Fragment>
    <div>
      <img src={fudexLogoSRC} className="fudexLogo" alt="logoFudex" />
    </div>
    <style jsx="jsx">
  {
    `.fudexLogo {
      max-height: 40px;
      display: flex;
      margin-top: -10px
      `
    }</style>
    </Fragment>
  );
};
export default FudexIcon;
