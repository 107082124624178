/* jshint esversion: 6 */

import { call } from 'redux-saga/effects';

// process STARTUP actions

export function * startup (api, {state}) {
if (state) {
if(state.session && state.session.authToken){
    	yield call(api.setHeader,'Authorization', 'Token '+state.session.authToken);
	}
}
}
