/* jshint esversion:6 */

import { call, put,actionChannel,take } from 'redux-saga/effects';
import TicketsActions from '../redux/TicketsRedux';



export function* getTickets(api, {grabType}) {

  // 1- Create a channel for request actions
  const requestChan = yield actionChannel('STARTUP')
  while (true) {
    // 2- take from the channel
     yield take(requestChan)
    // 3- Note that we're using a blocking call
    const response = yield call(api.tickets.getTickets, grabType);
    if (response.ok) {
      yield put(TicketsActions.ticketsSuccess(response.data));
    }
    else {
      yield put(TicketsActions.ticketsFailure('getTicketsFailed'));
    }
  }
}



/*export function * getTickets (api) {
  const response = yield call(api.tickets.getTickets);
  if (response.ok) {
    yield put(TicketsActions.ticketsSuccess(response.data));
  }
  else {
    yield put(TicketsActions.ticketsFailure('getTicketsFailed'));
  }
}*/

export function * retireTicket (api, {ticketId, code}) {
  const response = yield call(api.tickets.retireTicket, ticketId, code);
  if (response.ok) {
    yield put(TicketsActions.retireTicketSuccess(response.data));  // Ticket completo modificado
  }
  else {
    yield put(TicketsActions.retireTicketFailure('retireTicketFailed'));
  }
}
