/* jshint esversion: 6 */
import { takeLatest } from 'redux-saga/effects';
import API from '../api';

/* ------------- Types ------------- */

import { LoginTypes } from '../redux/LoginRedux';
import { MenuTypes } from '../redux/MenuRedux';
import { TicketsTypes } from '../redux/TicketsRedux';
import { LocalesTypes } from '../redux/LocalesRedux';
import { StartupTypes } from '../redux/StartUpRedux';

/* ------------- Sagas ------------- */

import {login, logout, signUp, facebookLogin, persistSessionData, loginGuest, googleLogin} from './LoginSagas';
import { getMenu } from './MenuSagas';
import { getTickets,retireTicket } from './TicketsSagas';
import { getLocales } from './LocalesSagas';
import { startup } from './StartupSagas';

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = API;

/* ------------- Connect Types To Sagas ------------- */

export default function * root () {
  yield [
    takeLatest(LoginTypes.LOGIN_REQUEST, login, api),
    takeLatest(LoginTypes.LOGIN_GUEST_REQUEST, loginGuest, api),
    takeLatest(LoginTypes.LOGOUT, logout, api),
    takeLatest(LoginTypes.FACEBOOK_LOGIN_REQUEST, facebookLogin, api),
    takeLatest(LoginTypes.FACEBOOK_LOGIN_SUCCESS, persistSessionData, api),
    takeLatest(LoginTypes.GOOGLE_LOGIN_REQUEST, googleLogin, api),
    takeLatest(LoginTypes.GOOGLE_LOGIN_SUCCESS, persistSessionData, api),
    takeLatest(LoginTypes.LOGIN_SUCCESS, persistSessionData, api),
    takeLatest(MenuTypes.MENU_REQUEST, getMenu, api),
    takeLatest(TicketsTypes.TICKETS_REQUEST, getTickets, api),
    takeLatest(TicketsTypes.RETIRE_TICKET_REQUEST, retireTicket, api),
    takeLatest(LocalesTypes.LOCALES_REQUEST, getLocales, api),
    takeLatest(LoginTypes.SIGN_UP_REQUEST, signUp, api),
    takeLatest(LoginTypes.SIGN_UP_SUCCESS, persistSessionData, api),
    takeLatest(StartupTypes.STARTUP, startup, api),
  ];
}
