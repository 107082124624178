import React from "react";
import fonts from "../../theme/fonts";

const OrderTotalWithDiscount = (props) => {

    const getTotalWithDiscount = () => {
        return (
            <div className="payment-container">
                {props.discount && (
                    <div className="text">
                        ${parseFloat(
                        parseInt(props.total) * (1 - parseInt(props.discount) / 100) + parseInt(props.deliveryPrice)
                    ).toFixed(2)}
                    </div>
                )}

                {props.discount ? (<div className={`${props.discount ? "text-smaller" : "text"}`}>
                    ${parseFloat(
                        parseInt(props.total) + parseInt(props.deliveryPrice)
                    ).toFixed(2)}
                </div>): (<div className={`${props.discount ? "text-smaller" : "text"}`}>
                    ${parseFloat(
                        parseInt(props.total) + parseInt(props.deliveryPrice)
                    ).toFixed(2)}
                </div>
                )}

                <style jsx>{`

                    .payment-container {
                        
                    }
                    .text-smaller {
                      font-family: ${fonts.main};
                      text-decoration: line-through;
                      font-style: normal;
                      font-weight: 600;
                      font-size: 20px;
                      text-align: right;
                      line-height: 16px;
                      -webkit-letter-spacing: 0.5px;
                      -moz-letter-spacing: 0.5px;
                      -ms-letter-spacing: 0.5px;
                      letter-spacing: 0.5px;
                      color: #9292a6;
                      margin-right: 6px;
                      margin-top: 6px;
                    }
                    .text {
                      font-family: ${fonts.main};
                      font-style: normal;
                      font-weight: 600;
                      font-size: 24px;
                      line-height: 29px;
                      text-align: right;
                      letter-spacing: -0.02em;
                    }
                  `}
                </style>
            </div>
        )
    };

  return (
    <div className="totalContent">
      <div className="text">Total a pagar</div>
      <div className="contentLeft">
       {getTotalWithDiscount()}
      </div>
      <style jsx>{`
        .totalContent {
          flex-direction: row;
          justify-content: space-between;
          display: flex;
          padding-left: 16px;
          padding-right: 16px;
        }
        .contentLeft {
          flex-direction: row;
          display: flex;
        }
        .line {
          text-decoration: line-through;
        }
        .text {
          font-family: ${fonts.main};
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          
          letter-spacing: -0.02em;
            
          color: #222222;

        }
      `}</style>
    </div>
  );
};

export default OrderTotalWithDiscount;
