const Locales = (api) => {
  const getLocales = (latitude, longitude, quantity, serviceType, organization, search) => api.get(`locales/local/?latitude=${latitude}&longitude=${longitude}&quantity=${quantity
  }${serviceType ? `&serviceType=${serviceType}` : ''}${organization ? `&organization=${organization}` : ''}${search ? `&name=${search}&product=${search}` : ''}`);

  const getLocations = (parameters) => api.get('locales/location', parameters);

  const getLocal = (parameters) => api.get('locales/local', parameters);

  const getLatestLocals = (organization, serviceType) => api.get(`locales/me/latestPlaces/?${organization ? `organization=${organization}` : ''}${serviceType ? `${organization ? '&' : ''}serviceType=${serviceType}` : ''}`);

  const checkDelivery = (placeId, parameters) => api.get(`locales/local/${placeId}/delivery/validate`, parameters);

  return {
    getLocales,
    getLocal,
    getLocations,
    getLatestLocals,
    checkDelivery,
  };
};

export default Locales;
