import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setOrderToPay: ["orderToPay"],
  addOrderItemRequest: ["orderItem"],
  addOrderItemSuccess: ["orderItem"],
  addOrderItemFailure: ["error"],
  addOrderItemQuantity: ["orderItem"],
  removeOrderItemQuantity: ["orderItem"],
  orderNotAvailableMessage: ["errorMessage"],
  clearOrderNotAvailableMessage: null,
  setDiscount: ["setDiscount"],
  removeOrderItem: ["indexId"],
  sendOrderRequest: ["order", "accountId", "localType"],
  sendOrderSuccess: null,
  sendOrderFailure: [],
  setSavedOrder: ["order"],
  setCurrentBar: ["bar", "orderType", "placeId"],
  discardOrder: null,
  clearOrder: null,
  setGeoLocation: ["geolocation"],
  setSelectLocation: ["selectLocation"],
  setPlaceId: ["placeId"],
  setPaymentMethod: ["paymentMethod"],
  setProcessedOrder: ["processed"],
  setTableNumber: ["tableNumber"],
  setPhoneNumber: ["phoneNumber"],
  setUpSelling: ["upSelling"],
});

export const OrderTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  order: null,
  error: null,
  errorMessage: null,
  fetching: false,
  returnedOrder: null,
  orderToPay: null,
  currentBar: null,
  geolocation: null,
  orderType: null,
  placeId: null,
  selectLocation: null,
  paymentMethod: null,
  discount: null,
  processed: false,
  tableNumber: null,
  phoneNumber: "",
  upSelling: false,
});

/* ------------- Reducers ------------- */

export const setOrderToPay = (state, { orderToPay }) => {
  return { ...state, fetching: false, orderToPay };
};

export const addOrderItemRequest = (state, action) => {
  const { orderItem } = action;
  let order;
  if (!state.order) {
    order = [orderItem];
    return { ...state, order };
  } else {
    order = state.order;
    let index;
    if (orderItem.selectedOptions.length > 0){
      index = order.findIndex(o => o.producto.id === orderItem.producto.id && o.selectedOptions[0].id === orderItem.selectedOptions[0].id);
    } else {
      index = order.findIndex(o => o.producto.id === orderItem.producto.id);
    }

    if (index !== -1) {
      const sameProduct = JSON.parse(JSON.stringify(order[index]));
      sameProduct.quantity += orderItem.quantity;
      const newOrder = [...order.slice(0, index), sameProduct, ...order.slice(index+1)];
      return { ...state, order: newOrder };
    }

    else {
      const newOrder = [...order, orderItem];
      return { ...state, order: newOrder };
    }

  }
};

export const addOrderItemQuantity = (state, action) => {
  const { orderItem } = action;
  const order = state.order;
  let index;
  if (orderItem.selectedOptions.length > 0){
    index = order.findIndex(o => o.producto.id === orderItem.producto.id && o.selectedOptions[0].id === orderItem.selectedOptions[0].id);
  } else {
    index = order.findIndex(o => o.producto.id === orderItem.producto.id);

  }

  if (index !== -1) {
    const sameProduct = JSON.parse(JSON.stringify(order[index]));
    sameProduct.quantity += 1;
    const newOrder = [...order.slice(0, index), sameProduct, ...order.slice(index+1)];
    return { ...state, order: newOrder };
  }
}

export const removeOrderItemQuantity = (state, action) => {
  const { orderItem } = action;
  const order = state.order;
  let index;
  if (orderItem.selectedOptions.length > 0){
    index = order.findIndex(o => o.producto.id === orderItem.producto.id && o.selectedOptions[0].id === orderItem.selectedOptions[0].id);
  } else {
    index = order.findIndex(o => o.producto.id === orderItem.producto.id);

  }


  if (index !== -1) {
    const sameProduct = JSON.parse(JSON.stringify(order[index]));
    sameProduct.quantity -= 1;
    let newOrder;
    if (sameProduct.quantity <= 0)
      newOrder = [...order.slice(0, index), ...order.slice(index+1)];

    else
      newOrder = [...order.slice(0, index), sameProduct, ...order.slice(index+1)];

    return { ...state, order: newOrder };
  }
}

export const orderNotAvailableMessage = (state, action) => {
  const { errorMessage } = action;
  return { ...state, errorMessage: errorMessage };
};

export const clearOrderNotAvailableMessage = (state) => {
  return { ...state, errorMessage: null };
};

export const addOrderItemSuccess = (state, action) => {
  const { orderItem } = action;
  return { ...state, fetching: false, error: null, orderItem: orderItem };
};

export const addOrderItemFailure = (state, { error }) => {
  return { ...state, fetching: false, error };
};

export const setSavedOrder = (state, { order }) => {
  return { ...state, savedOrder: order };
};

export const removeOrderItem = (state, action) => {
  const { indexId } = action;
  let order = [...state.order];

  order.splice(indexId, 1);

  if (order.length === 0) {
    return { ...state, order: null };
  } else {
    return { ...state, order };
  }
};

export const sendOrderRequest = (state, action) => {
  return { ...state, fetching: true, error: null };
};

export const sendOrderSuccess = (state) => INITIAL_STATE;

export const sendOrderFailure = (state, { error }) => {
  return { ...state, fetching: false, error };
};

export const discardOrder = (state) => {
  return {...INITIAL_STATE, currentBar: state.currentBar};
};

export const clearOrder = (state) => INITIAL_STATE;

export const setCurrentBar = (state, action) => {
  const { bar, orderType, placeId } = action;
  return {
    ...state,
    currentBar: bar,
    orderType: orderType ? orderType : bar.serviceType,
    placeId: placeId ? placeId : null,
    order: null,
  };
};

export const setGeoLocation = (state, action) => {
  const { geolocation } = action;
  return { ...state, geolocation: geolocation };
};

export const setSelectLocation = (state, action) => {
  const { selectLocation } = action;
  return { ...state, selectLocation };
};

export const setPlaceId = (state, action) => {
  const { placeId } = action;
  return { ...state, placeId };
};

export const setPaymentMethod = (state, action) => {
  const { paymentMethod } = action;
  state.paymentMethod = paymentMethod;
  return { ...state, paymentMethod };
};

export const setDiscount = (state, action) => {
  const { setDiscount } = action;

  return { ...state, setDiscount };
};

export const setProcessedOrder = (state, action) => {
  const { processed } = action;
  return { ...state, processed };
};

export const setTableNumber = (state, action) => {
  const { tableNumber } = action;
  return { ...state, tableNumber };
};

export const setPhoneNumber = (state, action) => {
  const { phoneNumber } = action;
  return { ...state, phoneNumber };
};

export const setUpSelling = (state, action) => {
  const { upSelling } = action;
  return { ...state, upSelling };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ORDER_TO_PAY]: setOrderToPay,
  [Types.ADD_ORDER_ITEM_REQUEST]: addOrderItemRequest,
  [Types.ADD_ORDER_ITEM_SUCCESS]: addOrderItemSuccess,
  [Types.ADD_ORDER_ITEM_FAILURE]: addOrderItemFailure,
  [Types.ADD_ORDER_ITEM_QUANTITY]: addOrderItemQuantity,
  [Types.REMOVE_ORDER_ITEM_QUANTITY]: removeOrderItemQuantity,
  [Types.ORDER_NOT_AVAILABLE_MESSAGE]: orderNotAvailableMessage,
  [Types.CLEAR_ORDER_NOT_AVAILABLE_MESSAGE]: clearOrderNotAvailableMessage,
  [Types.DISCARD_ORDER]: discardOrder,
  [Types.CLEAR_ORDER]: clearOrder,
  [Types.SEND_ORDER_REQUEST]: sendOrderRequest,
  [Types.SEND_ORDER_SUCCESS]: sendOrderSuccess,
  [Types.SEND_ORDER_FAILURE]: sendOrderFailure,
  [Types.SET_SAVED_ORDER]: setSavedOrder,
  [Types.REMOVE_ORDER_ITEM]: removeOrderItem,
  [Types.SET_CURRENT_BAR]: setCurrentBar,
  [Types.SET_GEO_LOCATION]: setGeoLocation,
  [Types.SET_SELECT_LOCATION]: setSelectLocation,
  [Types.SET_PLACE_ID]: setPlaceId,
  [Types.SET_PAYMENT_METHOD]: setPaymentMethod,
  [Types.SET_DISCOUNT]: setDiscount,
  [Types.SET_PROCESSED_ORDER]: setProcessedOrder,
  [Types.SET_TABLE_NUMBER]: setTableNumber,
  [Types.SET_PHONE_NUMBER]: setPhoneNumber,
  [Types.SET_UP_SELLING]: setUpSelling,
});

/* ------------- Selectors ------------- */

export const orderTotalPrice = (state) => {
  var price = 0;
  var tmpPrice = 0;
  if (state) {
    for (var i = state.length - 1; i >= 0; i--) {
      tmpPrice = parseInt(state[i].producto.price);
      for (var key in state[i].selectedOptions) {
        tmpPrice += parseInt(state[i].selectedOptions[key].price);
      }
      price += tmpPrice * state[i].quantity;
    }
  }
  return price;
};

export const discountTotalPrice = (state) => {
  var price = 0;
  var tmpPrice = 0;
  if (state) {
    for (var i = state.length - 1; i >= 0; i--) {
      tmpPrice =
        parseInt(state[i].producto.standarPrice) -
        parseInt(state[i].producto.price);
      for (var key in state[i].selectedOptions) {
        tmpPrice +=
          parseInt(state[i].selectedOptions[key].standarPrice) -
          parseInt(state[i].selectedOptions[key].price);
      }
      price += tmpPrice * state[i].quantity;
    }
  }
  return price;
};

export const orderCountItems = (state) => {
  if (!state) {
    return 0;
  }
  return state.reduce((prev, current, index) => {
    return prev + current.quantity;
  }, 0);
};

export const getUpSellingGrabType = (state) => {
  if (!state) {
    return null;
  }
  const bar = state.some((item) => item.producto.grab_from === "Bar");
  const Kitchen = state.some((item) => item.producto.grab_from === "Kitchen");

  if (bar && Kitchen) {
    return "All";
  } else {
    if (bar) {
      return "Kitchen";
    } else {
      return "Bar";
    }
  }
};

