import React, { Fragment } from "react";
import fonts from "../../theme/fonts";

const FdxText = ({
  fontFamily,
  fontWeight,
  fontSize,
  color,
  textAlign,
  flex,
  marginTop,
  children,
  lineHeight,
  textDecorationLine,
  marginBottom,
  marginLeft,
  marginRight,
  paddingBottom,
  paddingTop,
  backgroundColor,
  borderRadius,
  overflow,
  display,
  alignSelf,
  paddingLeft,
  paddingRight,
  letterSpacing,
  borderTopLeftRadius,
  borderTopRightRadius,
  position,
  top,
}) => {
  return (
    <Fragment>
      <span>{children}</span>
      {/* language=CSS*/}
      <style jsx>{`
        span {
          font-family: ${fontFamily ? fontFamily : fonts.main};
          font-weight: ${fontWeight ? fontWeight : "normal"};
          text-align: ${textAlign ? textAlign : "left"};
          font-size: ${fontSize ? fontSize : "12px"};
          color: ${color ? color : "#9B9B9B"};
          margin-left: ${marginLeft ? marginLeft : ""};
          margin-right: ${marginRight ? marginRight : ""};
          flex: ${flex ? flex : ""};
          margin-top: ${marginTop ? marginTop : ""};
          margin-bottom: ${marginBottom ? marginBottom : ""};
          line-height: ${lineHeight ? lineHeight : "normal"};
          text-decoration-line: ${textDecorationLine ? textDecorationLine : ""};
          padding-bottom: ${paddingBottom ? paddingBottom : ""};
          padding-top: ${paddingTop ? paddingTop : ""};
          padding-left: ${paddingLeft ? paddingLeft : ""};
          padding-right: ${paddingRight ? paddingRight : ""};
          background-color: ${backgroundColor ? backgroundColor : ""};
          border-radius: ${borderRadius ? borderRadius : ""};
          overflow: ${overflow ? overflow : ""};
          display: ${display ? display : ""};
          align-self: ${alignSelf ? alignSelf : ""};
          letter-spacing: ${letterSpacing ? letterSpacing : ""};
          border-top-left-radius: ${borderTopLeftRadius
            ? borderTopLeftRadius
            : ""};
          border-top-right-radius: ${borderTopRightRadius
            ? borderTopRightRadius
            : ""};
          position:${position ? position : ""};
          top:${top ? top : ""};
        }
      `}</style>
    </Fragment>
  );
};

export default FdxText;
