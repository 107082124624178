import React, { Fragment, Component } from "react";
import bagBlue from "../../Assets/Images/bagBlue.svg";
import tableIcon from "../../Assets/Images/tableIconInOrder.svg";
import OnPremiseSRC from "../../Assets/Images/OnPremiseBanner.svg";
import deliveryAddressSRC from "../../Assets/Images/deliveryAddress.svg";
import MenuBannerSRC from "../../Assets/Images/MenuBanner.svg";
import GoBack from "../App/components/GoBackIcon/GoBackIcon";
import { withRouter } from "react-router-dom";
import fonts from "../../theme/fonts";

const OrderType = {
  Delivery: "Delivery",
  TakeAway: "TakeAway",
  Table: "Table",
  OnPremise: "OnPremise",
  DigitalMenu: "DigitalMenu",
};

class OrderBanner extends Component {
  constructor(props) {
    super();
  }

  getTittle = () => {
    switch (this.props.serviceType) {
      case OrderType.TakeAway:
        return "Pedido para Take Away";

      case OrderType.Table:
        return "Servicio a la mesa";

      case OrderType.OnPremise:
        return "Modalidad autoservicio";

      case OrderType.DigitalMenu:
        return "Menú digital";

      case OrderType.Delivery:
        return " #QuedateEnCasa";

      default:
        return null;
    }
  };

  getSubTittle = () => {
    if (this.props.address) {
      var index = this.props.address.lastIndexOf(",");
    }

    switch (this.props.serviceType) {
      case OrderType.TakeAway:
        return "Tu pedido será preparado para llevar.";

      case OrderType.Table:
        return "Una vez confirmado tu pedido, te lo acercaremos a tu mesa.";

      case OrderType.OnPremise:
        return "Luego de terminar tu pedido, retiralo por la zona de entrega.";

      case OrderType.DigitalMenu:
        return "Cuando estés listo llamá al mozo para hacer tu pedido.";

      case OrderType.Delivery:
        return (
          "Tu pedido se entregará en: " + this.props.address.substring(0, index)
        );
      default:
        return null;
    }
  };

  getIcon = () => {
    switch (this.props.serviceType) {
      case OrderType.TakeAway:
        return bagBlue;

      case OrderType.Table:
        return tableIcon;

      case OrderType.OnPremise:
        return OnPremiseSRC;

      case OrderType.DigitalMenu:
        return MenuBannerSRC;

      case OrderType.Delivery:
        return deliveryAddressSRC;

      default:
        return null;
    }
  };

  goBack() {
    if (this.props.backUrl)
      this.props.history.push(this.props.backUrl);

    else
      this.props.history.goBack();
  }

  render = () => {
    return (
      <Fragment>
        <div className="bannerContainer">
          <div className="goBack">
            <GoBack color="black" onClick={() => this.goBack()} />
          </div>
          <div className="banerRow">
            <img className="oderImg" src={this.getIcon()} alt="order" />
            <div className="columnRight">
              <div className="textoP">{this.getTittle()}</div>
              <div className="textoS">{this.getSubTittle()}</div>
            </div>
          </div>
        </div>
        <style jsx>{`
          .bannerContainer {
            background-color: #f1f2f5;
            flex-direction: row;
            display: flex;
            margin-bottom: 8px;
          }
          .banerRow {
            flex-direction: row;
            display: flex;
          }
          .goBack {
            align-self: center;
            display: flex;
            margin-right: 20px;
          }
          .oderImg {
            width: 40px;
            height: 40px;
            align-self: center;
          }
          .columnRight {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
          }
          .textoP {
            font-family: ${fonts.main};
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
          }
          .textoS {
            font-family: ${fonts.main};
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            padding-right: 18px;
          }
        `}</style>
      </Fragment>
    );
  };
}

export default withRouter(OrderBanner);
