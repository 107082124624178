import React, { Component, Fragment } from "react";
import { Modal } from "reactstrap";
import { RoundedButton } from "./";
import Api from "../../api";
import closeSRC from "../../Assets/Images/close.svg";
import { connect } from "react-redux";
import LoginActions from "../../redux/LoginRedux";
import checkAddres from "../../Assets/Images/check.svg";
import { LinkButton } from "../../components/Common";
import circle from "../../Assets/Images/circle.svg";
import fonts from "../../theme/fonts";
import {isMobile} from 'react-device-detect';

class AddressModal extends Component {
  constructor(props) {
    super();
    this.state = {
      address: false,
      buttonDisabled: true,
      withoutNumber: false,
      additionalData: null,
      between: null,
      number: null,
      street: null,
      locality: null,
      error: null,
      place_id: null,
      //googleAutoComplete: new window.google.maps.places.AutocompleteService(),
      googleAutoComplete: null,
      //sessionToken: new window.google.maps.places.AutocompleteSessionToken(),
      sessionToken: null,
      googlePlaces: null,
      step: 1,
    };
  }

  componentDidMount() {
    if (window.google && window.google.maps) {
      this.setState({
        googleAutoComplete: new window.google.maps.places.AutocompleteService(),
        sessionToken: new window.google.maps.places.AutocompleteSessionToken(),
        googlePlaces: new window.google.maps.places.PlacesService(
          document.createElement("div")
        ),
      });
    } else {
      console.error('API de Google Maps no disponible.');
    }
  }

  onClose = () => {
    window.mixpanel.track("Close Insert Address", {
      view: "addressModal",
      loggedIn: this.props.user ? true : false,
    });
    this.cleanForm();
    this.props.onClose();
  };

  streetOnchange = (event) => {
    this.setState({
      street: event.target.value === "" ? null : event.target.value,
    });
    this.validateFormData();
  };

  localityOnchange = (event) => {
    this.setState({
      locality: event.target.value === "" ? null : event.target.value,
    });
    this.validateFormData();
  };

  numberOnchange = (event) => {
    this.setState({
      number: event.target.value === "" ? null : event.target.value,
    });
    this.validateFormData();
  };

  additionalDataOnChange = (event) => {
    this.setState({
      additionalData: event.target.value === "" ? null : event.target.value,
    });
    this.validateFormData();
  };

  betweenOnChange = (event) => {
    this.setState({
      between: event.target.value === "" ? null : event.target.value,
    });
  };

  validateFormData = () => {
    setTimeout(() => this.validateData(), 100);
  };

  validateData = () => {
    if (this.state.step === 3) {
      if (this.state.additionalData) {
        this.setState({ buttonDisabled: false });
      } else {
        this.setState({ buttonDisabled: true });
      }
    } else {
      if (this.state.number && this.state.street && this.state.locality) {
        this.setState({ buttonDisabled: false, error: null });
      } else {
        this.setState({ buttonDisabled: true });
      }
    }
  };

  confirmStep = async () => {
    this.setState({ error: null });
    if (this.state.step === 1) {
      window.mixpanel.track("Search Address", {
        view: "addressModal",
        loggedIn: this.props.user ? true : false,
      });
      if (!this.state.street) {
        this.setState({ error: "Ingresar calle" });
        this.inputStreet.focus();
        return;
      }
      if (!this.state.number) {
        this.setState({ error: "Ingresar numero" });
        this.inputNumber.focus();
        return;
      }
      if (!this.state.locality) {
        this.setState({ error: "Ingresar localidad o barrio" });
        this.inputLocality.focus();
        return;
      }

      const address =
        this.state.street +
        " " +
        this.state.number +
        " " +
        this.state.locality +
        " ";

      const componentRestrictions = { country: "Ar" };
      const types = ["address"];
      this.state.googleAutoComplete.getPlacePredictions(
        {
          input: address,
          sessionToken: this.state.sessionToken,
          componentRestrictions,
          types,
        },
        (suggests) => this.saveSuggests(suggests)
      );
    }
    if (this.state.placeDetail && this.state.step === 2) {
      this.setState({ step: 3, buttonDisabled: true });
    }

    if (this.state.step === 3 && this.state.additionalData) {
      if (!this.state.buttonDisabled) {
        const addressData = {
          additionalData: this.state.additionalData,
          formattedAddress: this.state.placeDetail.formatted_address,
          latitude: this.state.placeDetail.geometry.location.lat(),
          longitude: this.state.placeDetail.geometry.location.lng(),
          gmapsData: this.state.placeDetail,
          between: this.state.between,
        };
        window.mixpanel.track("Insert Address", {
          view: "addressModal",
          loggedIn: this.props.user ? true : false,
          additionalData: this.state.additionalData,
          formattedAddress: this.state.placeDetail.formatted_address,
          latitude: this.state.placeDetail.geometry.location.lat(),
          longitude: this.state.placeDetail.geometry.location.lng(),
          gmapsData: this.state.placeDetail,
        });
        if (this.props.user) {
          const request = await Api.login.createAddress(addressData);
          if (request.ok) {
            this.props.setCurrentAddress(request.data);
            this.props.success(
              this.state.placeDetail.geometry.location.lat(),
              this.state.placeDetail.geometry.location.lng()
            );
          } else {
            //verrrrr por q se hizo esto
          }
        } else {
          this.props.setCurrentAddress(addressData);
        }
        this.props.success(addressData.latitude, addressData.longitude);
        this.cleanForm();
      }
    }
  };

  cleanForm = () => {
    this.setState({
      step: 1,
      street: null,
      number: null,
      locality: null,
      renderSuggests: null,
      place_id: null,
      placeDetail: null,
      buttonDisabled: true,
      error: null,
      withoutNumber: null,
      additionalData: null,
      between: null,
    });
  };

  saveSuggests = (suggests) => {
    if (suggests) {
      this.setState({
        suggests,
        renderSuggests: true,
        buttonDisabled: true,
        step: 2,
      });
    } else {
      this.setState({
        error:
          "No se encontraron direcciones para los criterios de busqueda ingresados",
        buttonDisabled: true,
      });
    }
  };

  savePlaceDetail = (placeDetail) => {
    if (
      !placeDetail.address_components.find((item) =>
        item.types.find((item) => item === "street_number")
      )
    ) {
      this.setState({ withoutNumber: true });
    } else {
      this.setState({
        placeDetail,
        buttonDisabled: false,
        withoutNumber: false,
      });
    }
  };

  selectSuggest = (place_id) => {
    this.setState({ place_id, withoutNumber: false });
    this.state.googlePlaces.getDetails(
      { placeId: place_id, sessionToken: this.state.sessionToken },
      (placeDetail) => this.savePlaceDetail(placeDetail)
    );
  };

  goBack = () => {
    this.setState({
      renderSuggests: null,
      placeDetail: null,
      step: 1,
      buttonDisabled: true,
      place_id: null,
      withoutNumber: false,
    });
    this.validateFormData();
  };

  getButtonMsg = () => {
    switch (this.state.step) {
      case 1:
        return "BUSCAR DIRECCIÓN";
      case 2:
        return "CONFIRMAR DIRECCIÓN";
      case 3:
        return "CONTINUAR";
      default:
        return "";
    }
  };

  getTitle = () => {
    switch (this.state.step) {
      case 1:
        return "Ingresá tu dirección";
      case 2:
        return "¿Cual de estas direcciones es la correcta?";
      case 3:
        return "Completa tu dirección";
      default:
        return "";
    }
  };

  getSubTitle = () => {
    if (this.state.error) {
      return this.state.error;
    }
    if (this.state.step === 1) {
      return "Calle, Numero y Localidad o Barrio";
    }
    if (this.state.withoutNumber) {
      return "No se encontró la numeración o la misma es inexistente";
    }
    if (this.state.step === 3) {
      return "Agrega datos que faciliten al delivery encontrar el domicilio";
    }
  };

  getCheckAddress(item) {
    return <>
      <div className="suggestItem">
        {item.description.substring(
            0,
            item.description.indexOf(", Argentina")
        )}
      </div>

      <img
          className=""
          src={
            this.state.place_id === item.place_id
                ? checkAddres
                : circle
          }
          alt="check"
      />

      <style jsx="jsx">
        {`
          input {
            width: 100%;
            border: 0;
            border-bottom: 1px solid #dcdada;
            outline-color: transparent;
            font-size: 18px;
            line-height: 25px;
            font-family: ${fonts.main};
            font-style: normal;
          }
          ::placeholder {
            font-family: ${fonts.main};
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 25px;
            color: #B6B6B6;
          }
          .leftDiv {
            text-align: left;
          }
          .bottomButtonContainer {
            margin-top: 35px;
            padding-left: 16px;
            padding-right: 16px;
          }
          .modalContainer {
            padding: 15px;
          }
          .header {
            margin-bottom: 25px;
            margin-top: 5px;
            text-align: center;
            display: flex;
            flex-direction: column;
          }
          .title {
            font-family: ${fonts.main};
            font-style: normal;
            font-weight: 600;
            font-size: 19px;
            line-height: 26px;
            text-align: center;
            letter-spacing: 0.144375px;
            color: #000000;
            margin-bottom 5px;
          }
          .subtitle {
            font-family: ${fonts.main};
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 23px;
            text-align: center;
            color: #4A4A4A;
            padding-left: 20px;
            padding-right: 20px;
          }
          .imgContent {
            height: 20px;
            display: flex;
            justify-content: flex-end;
            cursor:pointer;
          }
          .closeImg {
            padding-right: 0px;
          }
          .bottomLinkContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          .modalBotttomContainer{
            padding-bottom: 25px;
            background-color: #EAEAEA;
          }
          .subtitleRed {
            font-family: ${fonts.main};
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 23px;
            text-align: center;
            color: red;
          }
          .inputData {
            margin-bottom: 11px;
            display: flex;
            padding-left: 5px;
            padding-right: 5px;
          }
          .suggest {
            display: flex;
            flex-direction: row;
            cursor:pointer;
            justify-content: space-between;
          }
          .divider {
            margin-top: 14px;
            border-bottom: 1px solid rgba(199, 199, 199, 0.32);
            margin-bottom: 15px;
          }
          .suggestItem {
            font-family: ${fonts.main};
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 25px;
            color: #000000;
          } 
          .linkCont {
            text-align: center;
            cursor:pointer;
          }
           `}
      </style>
    </>;
  }

  render() {
    const closable =
      this.props.closable != undefined ? this.props.closable : true;
    return (
      <Fragment>
        <Modal isOpen={this.props.state} centered>
          <div className="modalContainer">
            {closable && !isMobile && (
              <div className="imgContent" onClick={this.onClose}>
                <img src={closeSRC} className="closeImg" alt="close" />
              </div>
            )}
            {closable && isMobile && (
              <div className="imgContent" onTouchEnd={this.onClose}>
                <img src={closeSRC} className="closeImg" alt="close" />
              </div>
            )}
            <div className="header">
              <span className="title">{this.getTitle()}</span>
              <span
                className={
                  this.state.withoutNumber || this.state.error
                    ? "subtitleRed"
                    : "subtitle"
                }
              >
                {this.getSubTitle()}
              </span>
            </div>
            <div>
              {this.state.renderSuggests &&
                this.state.step === 2 &&
                this.state.suggests.map((item, index) => (
                  <Fragment key={item.place_id}>
                    {!isMobile && (
                        <div className="suggest"
                             onClick={() => this.selectSuggest(item.place_id)}>
                          {this.getCheckAddress(item)}
                        </div>
                    )}

                    {isMobile && (
                        <div className="suggest"
                             onTouchEnd={() => this.selectSuggest(item.place_id)}>
                          {this.getCheckAddress(item)}
                        </div>
                    )}
                    <div className="divider" />
                  </Fragment>
                ))}
            </div>
            {!this.state.renderSuggests && (
              <div>
                <div className="inputData">
                  <input
                    data-hj-allow
                    ref={(x) => (this.inputStreet = x)}
                    value={this.state.street}
                    placeholder={"Calle..."}
                    onChange={this.streetOnchange}
                  />
                </div>
                <div className="inputData">
                  <input
                    data-hj-allow
                    ref={(x) => (this.inputNumber = x)}
                    value={this.state.number}
                    type="number"
                    placeholder={"Numero..."}
                    onChange={this.numberOnchange}
                  />
                </div>
                <div className="inputData">
                  <input
                    data-hj-allow
                    ref={(x) => (this.inputLocality = x)}
                    value={this.state.locality}
                    placeholder={"Localidad o Barrio..."}
                    onChange={this.localityOnchange}
                  />
                </div>
              </div>
            )}
            {this.state.step === 3 && (
              <Fragment>
                <div className="inputData">
                  <input
                    data-hj-allow
                    ref={(x) => (this.inputAdditionalData = x)}
                    value={this.state.additionalData}
                    placeholder={"Casa, piso, timbre o aclaraciones..."}
                    onChange={this.additionalDataOnChange}
                  />
                </div>
                <div className="inputData">
                  <input
                    data-hj-allow
                    value={this.state.between}
                    placeholder={"Entre calles..."}
                    onChange={this.betweenOnChange}
                  />
                </div>
              </Fragment>
            )}
            <div className="bottomButtonContainer">
              <RoundedButton
                disabled={this.state.buttonDisabled}
                textColor="white"
                onClick={this.confirmStep}
              >
                {this.getButtonMsg()}
              </RoundedButton>
              {this.state.step === 2 && (
                <div className="linkCont">
                  <LinkButton onClick={this.goBack}>
                    Ingresar otra dirección
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </Modal>
        <style jsx="jsx">
          {`
          input {
            width: 100%;
            border: 0;
            border-bottom: 1px solid #dcdada;
            outline-color: transparent;
            font-size: 18px;
            line-height: 25px;
            font-family: ${fonts.main};
            font-style: normal;
          }
          ::placeholder {
            font-family: ${fonts.main};
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 25px;
            color: #B6B6B6;
          }
          .leftDiv {
            text-align: left;
          }
          .bottomButtonContainer {
            margin-top: 35px;
            padding-left: 16px;
            padding-right: 16px;
          }
          .modalContainer {
            padding: 15px;
          }
          .header {
            margin-bottom: 25px;
            margin-top: 5px;
            text-align: center;
            display: flex;
            flex-direction: column;
          }
          .title {
            font-family: ${fonts.main};
            font-style: normal;
            font-weight: 600;
            font-size: 19px;
            line-height: 26px;
            text-align: center;
            letter-spacing: 0.144375px;
            color: #000000;
            margin-bottom 5px;
          }
          .subtitle {
            font-family: ${fonts.main};
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 23px;
            text-align: center;
            color: #4A4A4A;
            padding-left: 20px;
            padding-right: 20px;
          }
          .imgContent {
            height: 20px;
            display: flex;
            justify-content: flex-end;
            cursor:pointer;
          }
          .closeImg {
            padding-right: 0px;
          }
          .bottomLinkContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          .modalBotttomContainer{
            padding-bottom: 25px;
            background-color: #EAEAEA;
          }
          .subtitleRed {
            font-family: ${fonts.main};
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 23px;
            text-align: center;
            color: red;
          }
          .inputData {
            margin-bottom: 11px;
            display: flex;
            padding-left: 5px;
            padding-right: 5px;
          }
          .suggest {
            display: flex;
            flex-direction: row;
            cursor:pointer;
            justify-content: space-between;
          }
          .divider {
            margin-top: 14px;
            border-bottom: 1px solid rgba(199, 199, 199, 0.32);
            margin-bottom: 15px;
          }
          .suggestItem {
            font-family: ${fonts.main};
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 25px;
            color: #000000;
          } 
          .linkCont {
            text-align: center;
            cursor:pointer;
          }
           `}
        </style>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.session.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentAddress: (address) =>
      dispatch(LoginActions.setCurrentAddress(address)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressModal);
