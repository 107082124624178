import React, { Component, Fragment } from "react";
import FdxText from "./FdxText";
import colors from "../../theme/colors";
import {FcGoogle} from "react-icons/all"

export default class RoundedButton extends Component {
  getText() {
    return this.props.text || this.props.children || "";
  }

  getLeftIcon() {
    if (this.props.leftIconName) {
      return (
        <Fragment>

          {this.props.googleIcon && (
            <FcGoogle className="leftIcon" style={{marginRight: 10, marginBottom: 2}}/>
          )}

          {!this.props.googleIcon && (
            <img className="leftIcon"
                 src={this.props.leftIconName}
                 alt="leftIconName"
            />
          )}
          <style jsx>{`
            .leftIcon {
              align-self: center;
              text-align: left;
              margin-top: -2px;
              margin-right: 15px;
            }
          `}</style>
        </Fragment>
      );
    }
    return null;
  }

  getRightIcon() {
    if (this.props.rightIconName) {
      return (
        <Fragment>
          <img
            className="rightIcon"
            alt="rightIconName"
            name={this.props.rightIconName}
            size={25}
            color="#FFFFFF"
          />
          <style jsx>{`
            .rightIcon {
              align-self: center;
              text-align: right;
              margin-top: -2px;
              margin-left: 15px;
            }
          `}</style>
        </Fragment>
      );
    }
    return null;
  }

  render = () => {
    const {
      backgroundColor,
      textColor,
      borderColor,
      onClick,
      disabled,
      width,
      height,
      marginLeft,
      marginRight,
    } = this.props;
    return (
      <Fragment>
        <button className="button" onClick={onClick}>
          <div className="componentsContainer">
            {this.getLeftIcon()}
            <FdxText
              fontSize={this.props.fontSize ? this.props.fontSize : "16px"}
              fontWeight="600"
              color={textColor}
              textAlign="center"
              marginTop="10px"
              marginBottom="10px"
            >
              {this.getText()}
            </FdxText>
            {this.getRightIcon()}
          </div>
        </button>
        <style jsx>
          {`
            .button {
              width: ${width ? width : "100%"};
              height: ${height ? height : "46px"};
              border-radius: 30px;
              background-color: ${backgroundColor
                ? backgroundColor
                : colors.main};
              align-self: stretch;
              justify-content: center;
              margin-top: 10px;
              margin-bottom: 10px;
              border-color: ${borderColor ? borderColor : ""};
              outline-color: transparent;
              opacity: ${disabled ? 0.5 : 1};
              border: ${borderColor ? "" : "none"};
              margin-left: ${marginLeft ? marginLeft : "0"};
              margin-right: ${marginRight ? marginRight : "0"};
              text-transform: uppercase;
            }
            .componentsContainer {
              flex: 1;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            }
          `}
        </style>
      </Fragment>
    );
  };
}
