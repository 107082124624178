/* jshint esversion:6 */

import { call, put } from 'redux-saga/effects';
import LocalesActions from '../redux/LocalesRedux';
import LoginActions from '../redux/LoginRedux';
//import { Actions as NavigationActions } from 'react-native-router-flux';

export function * getLocales (api, {latitude,longitude}) {
  const response = yield call(api.locales.getLocales, latitude, longitude);
  if (response.ok) {
    yield put(LocalesActions.localesSuccess(response.data));
  }
  else {
    yield put(LocalesActions.localesFailure('getLocalesFailed'));
    if (response.status === 401) {
      yield put(LoginActions.logout());
  //    NavigationActions.mainTabBar({type:'reset'});
    }
  }
}
