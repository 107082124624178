// @flow
/* jshint esversion: 6 */

import { combineReducers } from 'redux';
import configureStore from './CreateStore';
import rootSaga from '../sagas/';
  /* ------------- Assemble The Reducers ------------- */
  const rootReducer = combineReducers({
    session: require('./LoginRedux').reducer,
    menu: require('./MenuRedux').reducer,
    order: require('./OrderRedux').reducer,
    tickets: require('./TicketsRedux').reducer,
    locales: require('./LocalesRedux').reducer,
  });

  const {store, persistor} = configureStore(rootReducer, rootSaga);

  export default () => {
    return {store, persistor};
  };
  