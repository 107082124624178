import React, { Component, Fragment } from "react";
import FdxText from "./FdxText";

export default class LinkButton extends Component {
  getText = () => {
    return this.props.text || this.props.children || "";
  };

  getPlainText = () => {
    return this.props.plainText || "";
  };

  render = () => {
    const { underline } = this.props;
    return (
      <Fragment>
        <button className="button" onClick={this.props.onClick}>
          <div>
            <FdxText color="#000000" textAlign="center" fontSize="16px">
              {this.getPlainText()}
            </FdxText>
            <FdxText
              textAlign="center"
              fontSize={this.props.fontSize ? this.props.fontSize : "16px"}
              color={this.props.color ? this.props.color : "#000000"}
              textDecorationLine={underline ? underline : "underline"}
              fontWeight="600"
            >
              {this.getText()}
            </FdxText>
          </div>
        </button>
        <style jsx="jsx">
          {`
            .button {
              border: none;
              background-color: transparent;
              margin-top: ${this.props.marginTop
                ? this.props.marginTop
                : "10px"};
              margin-left: auto;
              margin-right: auto;
              outline-color: transparent;
            }
          `}
        </style>
      </Fragment>
    );
  };
}
