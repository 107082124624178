import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {getCurrentLocal} from '../../redux/LocalesRedux';
import {getProductById} from '../../redux/MenuRedux';
import {FdxText} from '../Common'
import colors from "../../theme/colors";

class NavigationBar extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }


  getLeftElement = () => {
    if (!this.props.leftElement) {
      return <div />;
    } else {
      return this.props.leftElement;
    }
  };

  getMiddleElement = () => {
    if (!this.props.middleElement) {
      return <div />;
    } else {
      if (this.props.middleElement === "printLocal") {
        if (this.props.local){
        return <FdxText fontSize="18px" color="white">{this.props.local.name}</FdxText>} else {
          return <FdxText fontSize="18px" color="white">{this.props.currentBar.name}</FdxText>}
      } if (this.props.middleElement === "printProduct") {
        return <FdxText fontSize="18px" color="white">{this.props.product.name}</FdxText>
      }
      else
      return this.props.middleElement;
    }
  };

  getRightElement = () => {
    if (!this.props.rightElement) {
      return <div />;
    } else {
      return this.props.rightElement;
    }
  };

  render = () => {
    return (
      <Fragment>
        <div className="header" style={this.props.style}>
          <div className="containerHeader">
            {this.getLeftElement()}
            {this.getMiddleElement()}
            {this.getRightElement()}
          </div>
        </div>
        <style jsx="jsx">
          {`
            .header {
              background-color: ${this.props.backgroundColor?this.props.backgroundColor:colors.grey};
              height: 60px;
              padding-top: 0;
              top: 0;
              right: 0;
              left: 0;
              border-bottom-width: 0.5px;
              border-bottom-color: "#828287";
              position: "absolute";
              z-index: ${this.props.zIndex?this.props.zIndex:1};
              transform: translateZ(0px);
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
            }
            .containerHeader {
              justify-content: space-between;
              display: flex;
              padding-top: 20px;
              padding-left: 5px;
              padding-right: 5px;
            }
          `}
        </style>
      </Fragment>
    );
  };
}

const mapStateToProps = (state, props) => {
  return {
    currentBar: state.order.currentBar,
    local: getCurrentLocal(state, props.match.params.code),
    product: getProductById(state, props.match.params.productId)
  };
};

export default withRouter(connect(mapStateToProps)(NavigationBar));
