import React, {Component, Fragment} from "react";
import accountIconSRC from "../../../../Assets/Images/accountIcon@2x.png";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {isMobile} from 'react-device-detect';


class UserIcon extends Component {
    render() {
        return (
            <Fragment>
                {!isMobile && (
                    <div className="userIcon" onClick={() => {
                        !this.props.user && !this.props.fetching ?
                            this.props.history.push('/signupselector') : this.props.history.push('/userData')
                        window.mixpanel.track('Click profile', {loggedIn: this.props.user ? true : false});
                    }}>
                        <img src={accountIconSRC} className="userImg" alt="accountIcon"/>
                    </div>)}

                {isMobile && (
                    <div className="userIcon" onTouchEnd={() => {
                        !this.props.user && !this.props.fetching ?
                            this.props.history.push('/signupselector') : this.props.history.push('/userData')
                        window.mixpanel.track('Click profile', {loggedIn: this.props.user ? true : false});
                    }}>
                        <img src={accountIconSRC} className="userImg" alt="accountIcon"/>
                    </div>)}
                <style jsx="jsx">
                    {` .userIcon {
                      margin-right: 5px;
                      cursor: pointer;
                      visibility: ${this.props.visibility ? this.props.visibility : 'inherit'};
                      display: flex;
                    }

                    .userImg {
                      padding-bottom: 7px;
                      max-height: 35px;
                      max-width: 28px;
                    }
                    `}
                </style>
            </Fragment>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.session.user,
        fetching: state.session.fetching
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserIcon));
