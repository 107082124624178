const Tickets = (api) => {

  const getTickets = (grabType) => api.get(`/locales/me/tickets${grabType?'?grabType='+grabType:''}`);
  const getLocalTickets = () => api.get(`/locales/me/tickets/card`);
  const retireTicket = (ticketId, data) => api.post(`/locales/me/ticket/${ticketId}/retire`,data);
  const getTicket = (ticketId) => api.get(`/locales/ticket/${ticketId}`);
  const getTicketForAccount = (accountId,grab_type) => api.get('/locales/cuenta/' + accountId + '/tickets?grab_type=' + grab_type);


  return {
    getTickets,
    getLocalTickets,
    retireTicket,
    getTicket,
    getTicketForAccount
  }
};
export default Tickets;
