const Login = (api) => {
  const login = (username, password, manager) =>
    api.post("core/user/login/", { username: username, password: password, manager: manager });
  const logout = () => api.delete("/core/user/logout/");
  const facebookLogin = (accessToken) =>
    api.post("core/user/fblogin/", { accessToken: accessToken });
  const googleLogin = (id, accessToken, email, name, lastName) =>
    api.post("core/user/Glogin/", { id: id, accessToken: accessToken, email: email, name: name, lastName: lastName });
  const signUp = (firstName, lastName, email, password, manager) =>
    api.post("core/user/register/", {
      username: email,
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      manager: manager,
    });
  const savePhone = (phone) => api.put("core/user/phone", { phone: phone });
  const createAddress = (payload) => api.post("core/user/address/", payload);
  const loginGuest = () => api.post("core/guest/");

  const getConditions = () =>
    api.get("preferences/global/general__terms_and_conditions");

  return {
    login,
    logout,
    facebookLogin,
    googleLogin,
    signUp,
    savePhone,
    createAddress,
    loginGuest,
    getConditions,
  };
};

export default Login;
