import { call, put } from "redux-saga/effects";
import MenuActions from "../redux/MenuRedux";
import { createMenu } from "../redux/MenuRedux";

import { filter, forEach } from "lodash";

export function* getMenu(api, { local_id }) {
  const response = yield call(api.menu.getMenu, local_id);
  if (response.ok) {
    let productos = response.data.results;

    forEach(productos, (producto) => {
      producto.gruposOpciones = filter(producto.gruposOpciones, (grupo) => {
        return grupo.status === "Active";
      });
      forEach(producto.gruposOpciones, (grupo) => {
        grupo.opciones = filter(grupo.opciones, (opcion) => {
          return opcion.status === "Active";
        });
      });
    });

    yield put(MenuActions.menuSuccess(createMenu(productos), productos));
  } else {
    yield put(MenuActions.menuFailure("getMenuFailed"));
  }
}
