import React, {Fragment} from 'react';
import instagramSRC from "../../Assets/Images/instagram.svg";
import facebookSRC from "../../Assets/Images/facebook.svg";
import twitterSRC from "../../Assets/Images/twitter.svg";
import { Link } from "react-router-dom";

const Networks = (props) => {
	return	<Fragment>
	  <div className="container">
    <a className="call" target="_blank" href="https://www.instagram.com/fudex.arg/"> 
      <img className="" src={instagramSRC} alt="instragram" /> </a>
      <a className="call" target="_blank" href="https://www.facebook.com/pages/category/App-Page/Fudex-248489269042553/"> 
      <img className="imgNetwork" src={facebookSRC} alt="facebook" /> </a>
      <a className="call" target="_blank" href="https://twitter.com/fudexarg"> 
      <img className="" src={twitterSRC} alt="twitter" /> </a>
    </div>
	<style jsx>{`
	.container {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
  .imgNetwork {
    margin-right: 32px;
    margin-left: 32px;
    margin-top: -4px;
  }
	`}</style>
	</Fragment>
}

export default Networks;

