import React, { Fragment } from 'react';
import ticketsTabIconSRC from '../../Assets/Images/ticketsTabIcon.svg';
import ticketsTabIconRedSRC from '../../Assets/Images/ticketsTabIconRed.svg';
import redMotorcycleSRC from '../../Assets/Images/redMotorcycle.svg';
import grayMotorcycleSRC from '../../Assets/Images/grayMotorcycle.svg';
import takeAwaySRC from '../../Assets/Images/takeAway.svg';
import greyTakeAwaySRC from '../../Assets/Images/greyTakeAway.svg';

import { FdxText } from '.';
import colors from '../../theme/colors';

const TabFooter = ({
  center, right, left, onClickCenter, onClickRight, onClickLeft, disableCenter, disableRight, disableLeft
}) => (
  <>

    <div className="tabFooter">

      {(!disableLeft) && (<div className="tabContainer divTab" onClick={onClickLeft}
           onTouchEnd={onClickLeft}>
        <img className="tabImg" src={left ? takeAwaySRC : greyTakeAwaySRC} alt="takeTabIcon" style={{ tint: 'red' }} fill="red" stroke="green" />
        <FdxText marginTop="4px" color={left ? colors.main : ''} display="flex" alignSelf="center">Retirar en persona</FdxText>
      </div>)}
      {(!disableCenter) && (<div className="tabContainer divTab" onClick={onClickCenter} onTouchEnd={onClickCenter}>
        <img className="tabImg" src={center ? redMotorcycleSRC : grayMotorcycleSRC} alt="pedirTabIcon" />
        <FdxText marginTop="4px" color={center ? colors.main : ''} display="flex" alignSelf="center">Envío a domicilio</FdxText>
      </div>)}
      {(!disableRight) && (<div className="tabContainer divTab" onClick={onClickRight} onTouchEnd={onClickRight}>
        <img className="tabImg" src={right ? ticketsTabIconRedSRC : ticketsTabIconSRC} alt="ticketsTabIcon" />
        <FdxText marginTop="4px" display="flex" color={right ? colors.main : ''} alignSelf="center">Mis Pedidos</FdxText>
      </div>)}
    </div>
    <style jsx="jsx">
      {`
        .tabFooter {
          padding-top: 10px;
          text-align: center;
          display: flex;
          height: 60px;
          position: fixed;
          justify-content: center;
          bottom: 0;
          background-color: white;
          width: 100%;
          border-top: 1px solid rgba(0, 0, 0, 0.3);
          z-index:99;
        }
        .divTab {
          width: 50%;
        }
        .tabImg {
          height: 20px;
          display: block;
          width: 25px;
          align-self: center;
        }
        .tabContainer {
          display:flex;
          flex-direction: column;
        }

      `}
    </style>
  </>
);

export default TabFooter;
