import React,{Component, Fragment} from 'react';

export default class FdxInputText extends Component {

	render  = ()  => {
		return (
      <Fragment>
			<input clasname= "textInput" type="text" ref='input' />
        <style jsx>{`
          .textInput {
            width:30px;
            height:175px;
            text-align: left;
            text-align-vertical: top;
            background-color: #FFFFFF;
            font-size: 16px;
            color: #000000;
          }
        `}
      </style>
      </Fragment>
		)
	}
}
