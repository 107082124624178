import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import ImmutablePersistenceTransform from './ImmutablePersistenceTransform'
import StartupActions from './StartUpRedux'
import OrderActions from './OrderRedux'

//import Api from "../api";

export default (rootReducer, rootSaga) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = [];
  const enhancers = [];

  /* ------------- Saga Middleware ------------- */

  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);



  /* ------------- Assemble Middleware ------------- */

  enhancers.push(applyMiddleware(...middleware));
  //enhancers.push(  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
  /* ------------- Persist configuration ------------- */

  const persistConfig = {
    key: 'primary',
    storage,
    blacklist: ['tickets'],
    transforms: [ImmutablePersistenceTransform],
    stateReconciler: autoMergeLevel2
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer)


  /* ------------- AutoRehydrate Enhancer ------------- */


  const store = createStore(persistedReducer, compose(...enhancers));
  const persistor = persistStore(store, {}, () => {
    store.dispatch(StartupActions.startup(store.getState()))
  })
  // kick off root saga
  sagaMiddleware.run(rootSaga);


  return {store, persistor}
}
