import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loginRequest: ["username", "password", "manager"],
  loginGuestRequest: null,
  loginSuccess: ["user", "authToken"],
  loginFailure: ["error"],
  logout: null,

  signUpRequest: ["first_name", "last_name", "email", "password", "manager"],
  signUpSuccess: ["user", "authToken"],
  signUpFailure: ["error"],

  facebookLoginRequest: ["accessToken"],
  facebookLoginSuccess: ["user", "authToken"],
  facebookLoginFailure: ["error"],

  googleLoginRequest: ["id", "accessToken", "email", "name", "lastName"],
  googleLoginSuccess: ["user", "authToken"],
  googleLoginFailure: ["error"],

  setCurrentAddress: ["address"],
  setCurrentPhone: ["phone"],

  persistSessionDataSuccess: null,

  setSessionTableNumber: ["tableNumber"],
});

export const LoginTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  error: null,
  fetching: false,
  user: null,
  accessToken: null,
  authToken: null,
  address: null,
  tableNumber: null,
});

/* ------------- Facebook Login Reducers ------------- */

export const facebookLoginRequest = (state: Object, action: Object) => {
  //  const { accessToken } = action
  return { ...state, fetching: true };
};

export const facebookLoginSuccess = (state: Object, action: Object) => {
  const { user, authToken } = action;
  return { ...state, user: user, authToken: authToken };
};

export const facebookLoginFailure = (state: Object, { error }: Object) => {
  return { ...state, fetching: false, error };
};

/* ------------- Google Login Reducers ------------- */

export const googleLoginRequest = (state: Object, action: Object) => {
  //  const { accessToken } = action
  return { ...state, fetching: true };
};

export const googleLoginSuccess = (state: Object, action: Object) => {
  const { user, authToken } = action;
  return { ...state, user: user, authToken: authToken };
};

export const googleLoginFailure = (state: Object, { error }: Object) => {
  return { ...state, fetching: false, error };
};

/* ------------- Login Reducers ------------- */

export const loginRequest = (state: Object, action: Object) => {
  return { ...state, fetching: true };
};

export const loginGuestRequest = (state: Object, action: Object) => {
  return { ...state, fetching: true };
};

export const loginSuccess = (state: Object, action: Object) => {
  const { user, authToken, accessToken } = action;
  return {
    ...state,
    user: user,
    authToken: authToken,
    accessToken: accessToken,
  };
};

export const loginFailure = (state: Object, { error }: Object) => {
  return { ...state, fetching: false, error };
};

export const logout = (state: Object) => {
  let address = null;
  if (state.address) {
    address = { ...state.address, id: null };
  }
  return {
    ...state,
    address,
    error: null,
    fetching: false,
    user: null,
    accessToken: null,
    authToken: null,
    firstTime: false,
    location: null,
  };
};

/* ------------- Sign Up Reducers ------------- */

export const signUpRequest = (state: Object, action: Object) => {
  //const { first_name, last_name, email, password } = action;
  return { ...state, fetching: true };
};

export const signUpSuccess = (state: Object, action: Object) => {
  const { user, authToken } = action;
  return { ...state, user: user, authToken: authToken };
};

export const signUpFailure = (state: Object, { error }: Object) => {
  return { ...state, fetching: false, error };
};

export const setCurrentAddress = (state, action) => {
  const { address } = action;

  return { ...state, address };
};

export const setCurrentPhone = (state, action) => {
  const { phone } = action;

  const profile = Object.assign({}, state.user.profile);
  const user = Object.assign({}, state.user);

  profile.phone = phone;
  user.profile = profile;

  return { ...state, user };
};

export const persistSessionDataSuccess = (state: Object, action: Object) => {
  return { ...state, fetching: false, error: null };
};

export const setSessionTableNumber = (state, action) => {
  const { tableNumber } = action;

  return { ...state, tableNumber };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_GUEST_REQUEST]: loginGuestRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,

  [Types.LOGOUT]: logout,

  [Types.SIGN_UP_REQUEST]: signUpRequest,
  [Types.SIGN_UP_SUCCESS]: signUpSuccess,
  [Types.SIGN_UP_FAILURE]: signUpFailure,

  [Types.FACEBOOK_LOGIN_REQUEST]: facebookLoginRequest,
  [Types.FACEBOOK_LOGIN_SUCCESS]: facebookLoginSuccess,
  [Types.FACEBOOK_LOGIN_FAILURE]: facebookLoginFailure,

  [Types.GOOGLE_LOGIN_REQUEST]: googleLoginRequest,
  [Types.GOOGLE_LOGIN_SUCCESS]: googleLoginSuccess,
  [Types.GOOGLE_LOGIN_FAILURE]: googleLoginFailure,

  [Types.SET_CURRENT_ADDRESS]: setCurrentAddress,
  [Types.SET_CURRENT_PHONE]: setCurrentPhone,
  [Types.PERSIST_SESSION_DATA_SUCCESS]: persistSessionDataSuccess,
  [Types.SET_SESSION_TABLE_NUMBER]: setSessionTableNumber,
});

/* ------------- Selectors ------------- */

export const isLoggedIn = (state: Object) => {
  return state.login.user !== null;
};
