import React, { Component, Fragment } from "react";
import chevronLeftSRC from "../../../../Assets/Images/ionicons_2-0-1_chevron-left_22_0_ffffff_none.png";
import chevronLeftSRCblack from "../../../../Assets/Images/ionicons_2-0-1_chevron-left_22_0_000000_none.png";
import closewhiteSRC from "../../../../Assets/Images/ionicons_2-0-1_android-close_18_0_ffffff_none.png";
import chevronLeftSRCgrey from "../../../../Assets/Images/chevronLeftGrey.svg";

import { withRouter } from "react-router-dom";

class GoBackIcon extends Component {
  render() {
    return (
      <Fragment>
        <div className="goBackIcon"
             onClick={this.props.onClick}>
          {!this.props.close ? (
            <img
              src={
                this.props.color === "black"
                  ? chevronLeftSRCblack
                  : this.props.color === "grey"
                  ? chevronLeftSRCgrey
                  : chevronLeftSRC
              }
              style={{width: '20px', height: '20px'}}
              alt="chevronLeft"
            />
          ) : (
            <img src={closewhiteSRC} alt="close" />
          )}
        </div>
        <style jsx="jsx">
          {`
            .goBackIcon {
              display: flex;
              justify-content: center;
              cursor:pointer;
              visibility: ${this.props.visibility
                ? this.props.visibility
                : "inherit"};
              z-index: 999;
            }
            .goBackIcon:hover {
              opacity: 0.75;
            }
          `}
        </style>
      </Fragment>
    );
  }
}
export default withRouter(GoBackIcon);
