import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCurrentLocal } from "../../redux/LocalesRedux";
import { getProductById } from "../../redux/MenuRedux";
import { FdxText } from "../Common";
import colors from "../../theme/colors";
import fonts from "../../theme/fonts";

class ProductNavigationBar extends Component {
  constructor(props) {
    super();

    this.state = {
      scroll: window.pageYOffsets,
    };
  }

  getLeftElement = () => {
    if (!this.props.leftElement) {
      return <div />;
    } else {
      return this.props.leftElement;
    }
  };

  getMiddleElement = () => {
    if (!this.props.middleElement) {
      return <div />;
    } else {
      if (this.props.middleElement === "printLocal") {
        return (
          <Fragment>
            <div className="productContainer">
              <span className="productName">
                {this.props.local
                  ? this.props.local.name
                  : this.props.currentBar.name}
              </span>
            </div>
            <style jsx="jsx">
              {`
                        .productContainer {
                            margin-left: 20px;
                            margin-top: -1px;
                        }    
                        .productName{
                          font-family: ${fonts.main};
                          font-weight: bold;
                            font-size: 18px;
                            color: white;
                            opacity: ${
                              this.state.scroll > 130
                                ? "1"
                                : 0 + (-0.5 + this.state.scroll / 130)
                            }
                            height:30px;
                            top:20px;
                            left: 50%;
                            transform: translate(-50%,0);
                            opacity: ${
                              this.state.scroll > 130
                                ? "0"
                                : 1 - this.state.scroll / 130
                            }
                            overflow: hidden;
                        }`}
            </style>
          </Fragment>
        );
      }
      if (this.props.middleElement === "printProduct") {
        return (
          <FdxText fontSize="18px" color="white">
            {this.props.product.name}
          </FdxText>
        );
      } else return this.props.middleElement;
    }
  };

  setScroll = () => {
    this.setState({ scroll: window.pageYOffset });
  };

  componentDidMount = () => {
    window.addEventListener("scroll", this.setScroll);
    this.setState({ scroll: window.pageYOffset });
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.setScroll);
  };

  getRightElement = () => {
    if (!this.props.rightElement) {
      return <div />;
    } else {
      return this.props.rightElement;
    }
  };

  render = () => {
    return (
      <Fragment>
        <div className="header" style={this.props.style}>
          <div className="containerHeader">
            {this.getLeftElement()}
            {this.getMiddleElement()}
          </div>
        </div>
        <style jsx="jsx">
          {`
            .header {
              background-image: url("${
                this.props.product.image ? this.props.product.image : "none"
              }");
              background-repeat: no-repeat;
              background-size: cover;
              background-position-y: 50%;
              background-color: ${colors.grey} ;
              height: ${
                this.state.scroll > 130 || !this.props.product.image
                  ? "60px"
                  : 190 - this.state.scroll + "px"
              };
              padding-top: 0;
              top: 0;
              right: 0;
              left: 0;
              border-bottom-width: 0.5px;
              border-bottom-color: "#828287";
              position: "absolute";
              z-index: ${this.props.zIndex ? this.props.zIndex : 1};
              transform: translateZ(0px);
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              
            }
            .containerHeader {
              justify-content: flex-start;
              display: flex;
              padding-top: 20px;
              padding-left: 5px;
              padding-right: 5px;
            }
          `}
        </style>
      </Fragment>
    );
  };
}

const mapStateToProps = (state, props) => {
  return {
    currentBar: state.order.currentBar,
    local: getCurrentLocal(state, props.match.params.code),
    product: getProductById(state, props.match.params.productId),
  };
};

export default withRouter(connect(mapStateToProps)(ProductNavigationBar));
