import React, {Fragment} from 'react';
import {Modal} from 'reactstrap';
import {FdxText,LinkButton,RoundedButton} from './';

const ConfirmModal = ({ title, message, onConfirm, onCancel,state, onYes, onNot}) => ( //se agrego "onYes/onNot"
  <Fragment>
  <Modal isOpen={state} centered>
  <div className="modalContainer">
  <div>
  <FdxText fontSize="16px" textAlign="center">{title}</FdxText>
  </div>
  <div>
  <RoundedButton textColor= "white" onClick={onConfirm}> {onYes? onYes : 'SI' }</RoundedButton>
  </div>
  <div>
  <LinkButton onClick={onCancel}>{onNot? onNot : 'No' }</LinkButton>
  </div>
  </div>
  </Modal>
        <style jsx ="jsx">
          {
            `
            .modalContainer {
              padding: 20px;
              display: flex;
              flex-direction: column;
              text-align: center;
            }
            .bottomDiv {
            align-self: flex-end;
            }
             `
          }</style>
      </Fragment>
    );


export default ConfirmModal;
