import React,{Component} from 'react';
import FdxText from './FdxText';

export default class FdxPriceText extends Component {

	getFormattedPrice = ()  => {
		let priceString = this.props.children || '0';
		let priceFloat = parseFloat(priceString);
		let priceDecimalPlaces = Number.isInteger(priceFloat) ? 0 : this.decimalPlaces(priceString);
		return (priceFloat < 0 ? '-' : '') + '$' + Math.abs(priceFloat)
			.toFixed(priceDecimalPlaces > 2 ? 2 : priceDecimalPlaces) // max two decimal digits
			.replace(".", ",") // replace decimal point character with ,
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") // use . as a separator
	}

	decimalPlaces = (num)  => {
		var match = (''+num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
		if (!match) { return 0; }
		return Math.max(
				 0,
				 // Number of digits right of decimal point.
				 (match[1] ? match[1].length : 0)
				 // Adjust for scientific notation.
				 - (match[2] ? +match[2] : 0));
	}

	render = ()  => {
		return (
			<FdxText fontSize={ this.props.fontSize? this.props.fontSize : "16px"} color="#000000"
			textAlign="right" lineHeight={this.props.lineHeight? this.props.lineHeight : '' }>
				{this.getFormattedPrice()}
			</FdxText>
		)
	}
}
